/* eslint-disable prefer-const */
export const objectCleaner = (obj) => {
    let newObj = obj;
  
    for (let prop in newObj) {
      if (newObj[prop] === '') {
        delete newObj[prop];
      }
    }
  
    return newObj;
  };
  