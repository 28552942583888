/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUI/ImageUI';
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/arrow-left.svg';

// Main component
const ViewCustomer = ({ setShowScreen, showScreen }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    customerName: '',
    contactNumber: '',
    address: '',
    status: '',
    photo: [],
    name: '',
    merchantName: '',
    branch: '',
    category: '',
    discount: '',
    orderTotal: '',
  });

  const handleCancel = () => {
    setShowScreen({
      show: 1,
      data: null,
    });
  };

  useEffect(() => {
    if (showScreen && showScreen?.data) {
      const { data } = showScreen;
      setFormData({
        ...data,
        customerName: data?.customer_name,
        contactNumber: data?.contact_no,
        address: data?.address,
        status: data?.status,
        photo: '',
        // photo: data?.customer_name,
        name: data?.merchant?.name,
        merchantName: data?.merchant_name,
        branch: data?.customer_name,
        category: data?.customer_name,
        discount: data?.customer_name,
        orderTotal: data?.customer_name,
        pick_up_location: data?.merchant?.address,
        date_time_pick_up: data?.created_date,
      });
    }
  }, [showScreen]);

  return (
    <Container>
      <Row>
        <Col className='p-0'>
          <p className={styles?.productHeader}>
            CUSTOMER INFORMATION <span>View Customer Information</span>
          </p>

          <div className={styles?.form}>
            <div className={styles?.column}>
              <FormText
                readOnly
                type='text'
                name='Customer Name'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                placeholder={'Enter Customer Name'}
                stateValue={formData?.customerName}
              />

              <FormText
                readOnly
                type='number'
                name='Contact Number'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                stateValue={formData?.contactNumber}
                placeholder={'Enter Contact Number'}
              />

              <FormText
                readOnly
                type='text'
                name='Address'
                setState={setFormData}
                placeholder={'Enter Address'}
                haveClass={styles?.fromGroup}
                stateValue={formData?.address}
              />
            </div>
            <div className={styles?.column}>
              <FormText
                readOnly
                type='text'
                name='Status'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                placeholder={'Enter Status'}
                stateValue={formData?.status}
              />
            </div>
            <div className={styles?.column}></div>
          </div>

          <p className={styles?.productHeader}>
            ORDER INFORMATION <span>View Order Information</span>
          </p>

          {formData?.order_details &&
            formData?.order_details.length > 0 &&
            formData?.order_details?.map((dd) => {
              return (
                <div className={styles?.form} key={dd?.id}>
                  <div className={styles?.photo}>
                    <span>Photo</span>

                    <div className={styles?.image}>
                      <ImageUI src={dd?.product?.images?.photo} />
                    </div>
                  </div>

                  <div className={styles?.column}>
                    <FormText
                      readOnly
                      type='text'
                      name='Name'
                      stateValue={dd?.item}
                      placeholder={'Enter Name'}
                      haveClass={styles?.fromGroup}
                    />

                    <FormText
                      readOnly
                      type='text'
                      name='Merchant Name'
                      setState={setFormData}
                      haveClass={styles?.fromGroup}
                      stateValue={formData?.merchantName}
                      placeholder={'Enter Merchant Name'}
                    />

                    <FormText
                      readOnly
                      type='text'
                      name='Category'
                      setState={setFormData}
                      placeholder={'Enter Category'}
                      haveClass={styles?.fromGroup}
                      stateValue={dd?.product?.category}
                    />
                  </div>

                  <div className={styles?.column}>
                    <FormText
                      readOnly
                      type='text'
                      name='Price'
                      stateValue={dd?.price}
                      placeholder={'Enter Price'}
                      haveClass={styles?.fromGroup}
                    />

                    <FormText
                      readOnly
                      type='text'
                      name='Quantity'
                      stateValue={dd?.qty}
                      haveClass={styles?.fromGroup}
                      placeholder={'Enter Quantity'}
                    />

                    <FormText
                      readOnly
                      type='text'
                      name='Sub Total'
                      haveClass={styles?.fromGroup}
                      placeholder={'Enter Sub Total'}
                      stateValue={+dd?.price * +dd?.qty}
                    />
                  </div>
                </div>
              );
            })}

          <div className={styles?.form}>
            <div className={styles?.column}>
              <FormText
                readOnly
                name='Pick Up Location'
                haveClass={styles?.fromGroup}
                placeholder={'Enter Pick Up Location'}
                stateValue={formData?.pick_up_location}
              />
            </div>

            <div className={styles?.column}>
              <FormText
                readOnly
                name='Date/time Of Pick-up'
                haveClass={styles?.fromGroup}
                placeholder={'Enter Date/time Of Pick-up'}
                stateValue={formData?.date_time_pick_up}
              />
            </div>

            <div className={styles?.column}></div>
          </div>

          <div className={styles?.form}>
            <div className={styles?.column}>
              <FormText
                readOnly
                name='Discount'
                haveClass={styles?.fromGroup}
                placeholder={'Enter Discount'}
                stateValue={formData?.promo_discounts}
              />
            </div>

            <div className={styles?.column}>
              <FormText
                readOnly
                name='Delivery Fee'
                haveClass={styles?.fromGroup}
                placeholder={'Enter Delivery Fee'}
                stateValue={formData?.delivery_fee}
              />
            </div>

            <div className={styles?.column}>
              <FormText
                readOnly
                name='Total'
                haveClass={styles?.fromGroup}
                placeholder={'Enter Total'}
                stateValue={formData?.total}
              />
            </div>
          </div>
          {/* 
          <div className={styles?.form}>
            <div className={styles?.photo}>
              <span>Rider Photo</span>

              <div className={styles?.image}>
                <ImageUpload
                  name='riderPhoto'
                  setState={setFormData}
                  stateValue={formData?.riderPhoto}
                />
              </div>
            </div>

            <div className={styles?.photo}>
              <span>Driver&apos;s License</span>

              <div className={styles?.image}>
                <ImageUpload
                  name='driverLicense'
                  setState={setFormData}
                  stateValue={formData?.driverLicense}
                />
              </div>
            </div>

            <div className={styles?.photo}>
              <span>Motorcycle Photo</span>

              <div className={styles?.image}>
                <ImageUpload
                  name='motorcyclePhoto'
                  setState={setFormData}
                  stateValue={formData?.motorcyclePhoto}
                />
              </div>
            </div>

            <div className={styles?.photo}>
              <span>OR / CR</span>

              <div className={styles?.image}>
                <ImageUpload name='oRCr' setState={setFormData} stateValue={formData?.oRCr} />
              </div>
            </div>
          </div>

          <div className={styles?.form}>
            <div className={styles?.column}>
              <FormText
                type='text'
                name='First Name'
                setState={setFormData}
                placeholder={'Enter First Name'}
                stateValue={formData?.firstName}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='number'
                name='Contact No'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                placeholder={'Enter Contact No.'}
                stateValue={formData?.contactNo}
              />

              <FormText
                type='text'
                name='Vehicle Brand'
                setState={setFormData}
                placeholder={'Enter Vehicle Brand'}
                stateValue={formData?.vehicleBrand}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='text'
                name='Vehicle Plate No'
                setState={setFormData}
                placeholder={'Enter Vehicle Plate No.'}
                stateValue={formData?.vehiclePlateNo}
                haveClass={styles?.fromGroup}
              />
            </div>

            <div className={styles?.column}>
              <FormText
                type='text'
                name='Last Name'
                setState={setFormData}
                placeholder={'Enter Last Name'}
                stateValue={formData?.lastName}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='date'
                name='Birthdate'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                placeholder={'Enter Birthdate.'}
                stateValue={formData?.birthDate}
              />

              <FormText
                type='text'
                name='Vehicle Model'
                setState={setFormData}
                placeholder={'Enter Vehicle Model'}
                stateValue={formData?.vehicleModel}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='text'
                name='OR No.'
                setState={setFormData}
                placeholder={'Enter OR No..'}
                stateValue={formData?.orNo}
                haveClass={styles?.fromGroup}
              />
            </div>

            <div className={styles?.column}>
              <FormText
                type='email'
                name='Email Address'
                setState={setFormData}
                placeholder={'Enter Email Address'}
                stateValue={formData?.emailAddress}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='number'
                name='Drivers License No.'
                setState={setFormData}
                haveClass={styles?.fromGroup}
                placeholder={`Enter Driver's License No.`}
                stateValue={formData?.driversLicenseNo}
              />

              <FormText
                type='text'
                name='Vehicle Color'
                setState={setFormData}
                placeholder={'Enter Vehicle Color.'}
                stateValue={formData?.vehicleColor}
                haveClass={styles?.fromGroup}
              />

              <FormText
                type='text'
                name='CR No.'
                setState={setFormData}
                placeholder={'Enter CR No.'}
                stateValue={formData?.cRNo}
                haveClass={styles?.fromGroup}
              />
            </div>
          </div> */}

          <div className={styles?.actions}>
            <Button className={styles?.cancel} onClick={handleCancel}>
              <MinusSVG />
              Go Back
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewCustomer;
