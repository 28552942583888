/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Row, Col } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { createRider, getridersState } from 'reducers/riderReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

const initialState = {
  cRNo: '',
  oRCr: [],
  oRNo: '',
  lastName: '',
  firstName: '',
  contactNo: '',
  birthdate: '',
  riderPhoto: [],
  vehicleBrand: '',
  vehicleModel: '',
  emailAddress: '',
  vehicleColor: '',
  driverLicense: [],
  vehiclePlateNo: '',
  motorcyclePhoto: [],
  driversLicenseNo: '',
};

// Main component
const AddRider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const riderState = useSelector(getridersState);
  const { createLogs } = riderState;

  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate('/riders');
  };

  const handleSave = async () => {
    const saveData = {
      oRCr: formData?.oRCr,
      riderPhoto: formData?.riderPhoto,
      driverLicense: formData?.driverLicense,
      motorcyclePhoto: formData?.motorcyclePhoto,

      oRNo: formData?.oRNo,
      cRNo: formData?.cRNo,
      lastName: formData?.lastName,
      firstName: formData?.firstName,
      contactNo: formData?.contactNo,
      birthdate: formData?.birthdate,
      vehicleBrand: formData?.vehicleBrand,
      vehicleModel: formData?.vehicleModel,
      emailAddress: formData?.emailAddress,
      vehicleColor: formData?.vehicleColor,
      vehiclePlateNo: formData?.vehiclePlateNo,
      driversLicenseNo: formData?.driversLicenseNo,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.warning('Please fill in the empty fields');
      return;
    }

    const apiData = new FormData();

    apiData.append('or_imgpath', saveData?.oRCr[0]);
    apiData.append('avatar', saveData?.riderPhoto[0]);
    apiData.append('motorcycle_imgpath', saveData?.motorcyclePhoto[0]);
    apiData.append('driver_license_imgpath', saveData?.driverLicense[0]);

    apiData.append('or_no', saveData?.oRNo);
    apiData.append('cr_no', saveData?.cRNo);
    apiData.append('lastname', saveData?.lastName);
    apiData.append('email', saveData?.emailAddress);
    apiData.append('firstname', saveData?.firstName);
    apiData.append('birthdate', saveData?.birthdate);
    apiData.append('contact_no', saveData?.contactNo);
    apiData.append('plate_no', saveData?.vehiclePlateNo);
    apiData.append('vehicle_model', saveData?.vehicleModel);
    apiData.append('vehicle_brand', saveData?.vehicleBrand);
    apiData.append('vehicle_color', saveData?.vehicleColor);
    apiData.append('driver_license_no', saveData?.driversLicenseNo);

    try {
      const response = await dispatch(createRider(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info('Successfully Created Rider Account');
        setFormData(initialState);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <PrivateLayout pageTitle='Add Rider'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>ADD RIDER</h4>
              <p>
                <span>RIDER LIST</span> / ADD RIDER
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addRider}>
              <p className={styles?.productHeader}>
                RIDER AND MOTORCYCLE INFORMATION <span>Enter Rider Information Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Rider Photo</span>

                  <div className={styles?.image}>
                    <ImageUpload
                      name='riderPhoto'
                      setState={setFormData}
                      stateValue={formData?.riderPhoto}
                    />
                  </div>
                </div>

                <div className={styles?.photo}>
                  <span>Driver&apos;s License</span>

                  <div className={styles?.image}>
                    <ImageUpload
                      name='driverLicense'
                      setState={setFormData}
                      stateValue={formData?.driverLicense}
                    />
                  </div>
                </div>

                <div className={styles?.photo}>
                  <span>Motorcycle Photo</span>

                  <div className={styles?.image}>
                    <ImageUpload
                      name='motorcyclePhoto'
                      setState={setFormData}
                      stateValue={formData?.motorcyclePhoto}
                    />
                  </div>
                </div>

                <div className={styles?.photo}>
                  <span>OR / CR</span>

                  <div className={styles?.image}>
                    <ImageUpload name='oRCr' setState={setFormData} stateValue={formData?.oRCr} />
                  </div>
                </div>
              </div>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='First Name'
                    setState={setFormData}
                    placeholder={'Enter First Name'}
                    stateValue={formData?.firstName}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='number'
                    name='Contact No'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact No.'}
                    stateValue={formData?.contactNo}
                  />

                  <FormText
                    type='text'
                    name='Vehicle Brand'
                    setState={setFormData}
                    placeholder={'Enter Vehicle Brand'}
                    stateValue={formData?.vehicleBrand}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='text'
                    name='Vehicle Plate No'
                    setState={setFormData}
                    placeholder={'Enter Vehicle Plate No.'}
                    stateValue={formData?.vehiclePlateNo}
                    haveClass={styles?.fromGroup}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Last Name'
                    setState={setFormData}
                    placeholder={'Enter Last Name'}
                    stateValue={formData?.lastName}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='date'
                    name='Birthdate'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    stateValue={formData?.birthdate}
                  />

                  <FormText
                    type='text'
                    name='Vehicle Model'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Vehicle Model'}
                    stateValue={formData?.vehicleModel}
                  />

                  <FormText
                    type='text'
                    name='OR No.'
                    setState={setFormData}
                    placeholder={'Enter OR No..'}
                    stateValue={formData?.oRNo}
                    haveClass={styles?.fromGroup}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='email'
                    name='Email Address'
                    setState={setFormData}
                    placeholder={'Enter Email Address'}
                    stateValue={formData?.emailAddress}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='number'
                    name='Drivers License No.'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={`Enter Driver's License No.`}
                    stateValue={formData?.driversLicenseNo}
                  />

                  <FormText
                    type='text'
                    name='Vehicle Color'
                    setState={setFormData}
                    placeholder={'Enter Vehicle Color.'}
                    stateValue={formData?.vehicleColor}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='text'
                    name='CR No.'
                    setState={setFormData}
                    placeholder={'Enter CR No.'}
                    stateValue={formData?.cRNo}
                    haveClass={styles?.fromGroup}
                  />
                </div>
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleSave}
                  className={styles?.add}
                  disabled={createLogs?.isLoading}
                >
                  <PlusSVG />
                  ADD RIDER
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default AddRider;
