import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import styles from './index.module.scss';

const ConfirmActionWithRemarks = ({ data, handleConfirm, show, type = '', onHide, ...rest }) => {
  const [reason, setReason] = useState('');

  return (
    <Modal
      size='md'
      centered
      {...rest}
      show={show}
      onHide={onHide}
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header} closeButton></Modal.Header>

      <Modal.Body className={styles?.body}>
        <p>Confirm {type}?</p>

        <Form.Group className={styles?.group}>
          <Form.Label>Reason</Form.Label>
          <Form.Control
            rows={5}
            as='textarea'
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Form.Group>

        <div className={styles?.buttons}>
          <Button
            className={styles?.submit}
            onClick={() => {
              if (reason === '') {
                toast.warning('Please fill in the reason');
                return;
              }

              onHide();
              handleConfirm(data, reason);
              setReason('');
            }}
          >
            OK
          </Button>

          <Button className={styles?.close} onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmActionWithRemarks;
