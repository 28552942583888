/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Row, Col, Table, Image } from 'react-bootstrap';

// Components
import FormText from 'components/GlobalUI/FormText';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/arrow-left.svg';

// Main component
const ViewTransaction = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({});
  const [ridersData, setRidersData] = useState(null);
  const [page, setPage] = useState(1);

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    }
    // eslint-disable-next-line no-undef
    if (_.isEmpty(formData)) {
      setFormData(location.state.dd);
    }
    console.log('local rider', location.state.dd.riderName);
    // eslint-disable-next-line no-undef
    if (!ridersData) {
      axios
        .get(`api/riders?page=${page}`)
        .then((res) => {
          if (res.data.data.length !== 0) {
            const rider = res.data.data.find(
              (elm) =>
                `${elm.firstname}${elm.middlename ? elm.middlename : ''}${elm.lastname}`.replace(
                  / /g,
                  '',
                ) ===
                (location.state.dd.riderName
                  ? location.state.dd.riderName.replace(/ /g, '')
                  : null),
            );
            rider ? setRidersData(rider) : setPage(page + 1);
          }
          if (res.data.data.length === 0) {
            setFormData((formData) => ({
              ...formData,
              riderName: 'Rider Not Found',
            }));
          }
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  }, [page]);

  console.log('formData', formData);

  return (
    <PrivateLayout pageTitle='View Promos'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>TRANSACTION DETAILS</h4>
              <p>
                <span>TRANSACTION</span> / TRANSACTION DETAILS
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.viewTransaction}>
              <p className={styles?.productHeader}>
                CUSTOMER INFORMATION <span>View Customer Information</span>
              </p>
              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Customer Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Customer Name'}
                    stateValue={`${formData?.included?.customer_details?.first_name} ${formData?.included?.customer_details?.last_name}`}
                  />

                  <FormText
                    readOnly
                    type='number'
                    name='Contact No.'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact Number'}
                    stateValue={formData?.included?.customer_details?.mobile_number}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Address'}
                    stateValue={formData?.included?.customer_details?.address}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Order ID'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Order ID'}
                    stateValue={formData?.id}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Status'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Status'}
                    stateValue={formData?.status}
                  />
                </div>
              </div>
              <p className={styles?.productHeader}>
                RIDER DETAILS <span>View Rider Information</span>
              </p>
              <div className={styles?.form}>
                <div className={styles?.column}>
                  <div className={styles?.photo}>
                    <span>Photo</span>
                    <Image name='avatar' src={ridersData?.files?.avatar} />
                  </div>
                </div>
                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Rider Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Rider Name'}
                    stateValue={formData?.riderName}
                  />

                  <FormText
                    readOnly
                    type='number'
                    name='Contact No.'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact Number'}
                    stateValue={ridersData?.contact_no}
                  />

                  <FormText
                    readOnly
                    type='text'
                    setState={setFormData}
                    name='Vehicle Plate No.'
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Address'}
                    stateValue={ridersData?.vehicle?.plate_no}
                  />
                </div>
              </div>
              <p className={styles?.productHeader}>
                MERCHANT INFORMATION <span>View Merchant Information</span>
              </p>
              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Merchant Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Merchant Name'}
                    stateValue={formData?.merchantName}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Delivery Fee'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Delivery Fee'}
                    stateValue={formData?.delivery_fee}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Merchant Email'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Merchant Email'}
                    stateValue={formData?.included?.merchant_details?.email}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Order Date/time'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Order Date/time'}
                    stateValue={formData?.created?.datetime}
                  />
                </div>
              </div>
              <p className={styles?.productHeader}>
                ORDER DETAILS <span>View Order Details</span>
              </p>
              <Table responsive striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Order No.</th>
                    <th>Food Code</th>
                    <th>Branch</th>
                    <th>Product Price</th>
                    <th>Discount</th>
                    <th>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {formData?.included?.items &&
                    formData?.included?.items.map((dd) => (
                      <tr key={dd?.code}>
                        <td>{formData?.id}</td>
                        <td>{formData?.order_no}</td>
                        <td>{dd?.code}</td>
                        <td>{dd?.branch}</td>
                        <td>{dd?.price}</td>
                        <td>{dd?.discount}</td>
                        <td>{dd?.totalAmount}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div className={styles?.actions}>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <ArrowLeftSVG />
                  BACK
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default ViewTransaction;
