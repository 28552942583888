/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Container, Row, Col, ProgressBar } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUI/ImageUI';
import { selectAuth } from 'reducers/authReducer';
import BarChart from 'components/GlobalUI/BarChartUI';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { getMerchants, getMerchantsState } from 'reducers/merchantReducer';
import PasswordPrompt from 'components/GlobalUI/Modal/PasswordPrompt/PasswordPrompt';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/blank-profile.png';
import { ReactComponent as MerchantSVG } from 'assets/svg/store.svg';
import { ReactComponent as ClipboardSVG } from 'assets/svg/clipboard.svg';
import { ReactComponent as RefreshSVG } from 'assets/svg/arrows-rotate.svg';
import { ReactComponent as MotorCycleSVG } from 'assets/svg/motorcycle.svg';
import { ReactComponent as ArrowRightSVG } from 'assets/svg/arrow-right.svg';

const barData = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL'],
  datasets: [
    {
      yAxisID: 'y',
      fill: true,
      borderColor: '#21CE50',
      label: 'Completed Deliveries',
      data: [0, 100, 150, 100, 150, 200, 200],
      backgroundColor: 'rgba(33, 206, 80, 0.2)',
    },
    {
      yAxisID: 'y1',
      fill: true,
      borderColor: '#E93423',
      label: 'Cancelled Deliveries',
      data: [0, 50, 50, 100, 100, 50, 50],
      backgroundColor: 'rgba(233, 52, 35, 1)',
    },
  ],
};

// Main component
const Dashboard = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loginStoreState = useSelector(selectAuth);
  const { user_type } = loginStoreState;

  const [overview, setOverview] = useState('Month');
  const [merchantList, setMerchantList] = useState([]);
  const [dashboardData, setDashboardData] = useState();

  const handleGenerate = () => {
    navigate('/dashboard/generate-report');
  };

  const handleFilter = (e) => {
    setOverview(e.target.value);
  };

  const handleMerchantSelect = (id) => {
    navigate(`/merchants/active/${id}`);
  };

  const handleGotoMerchant = () => {
    navigate(`/merchants`);
  };

  const getGreeting = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  const getMerchantList = async () => {
    try {
      const response = await dispatch(
        getMerchants({
          'included[]': 'items',
        }),
      );

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      const subArray = response?.payload?.data.slice(0, 5);

      const newData = subArray.map((dd) => {
        return {
          ...dd,
          email: dd?.email,
          status: dd?.status,
          address: dd?.address,
          merchantName: dd?.name,
          contactNo: dd?.contact_no,
          image: dd?.files?.avatar || '',
        };
      });

      setMerchantList(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const getDashboardData = async () => {
    try {
      axios.get(`api/admin-dashboard-analytics`).then((response) => {
        setDashboardData(response?.data);
      });
    } catch (error) {
      console.log('Error', error);
    }
  };

  useEffect(() => {
    getMerchantList();
    getDashboardData();
  }, []);

  console.log('dashboardData', dashboardData);

  return (
    <PrivateLayout pageTitle='Dashboard'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>
                {getGreeting()} {user_type}!
              </h4>
              <p>
                <span>DASHBOARD</span> / ANALYTICS AND MONITORING
              </p>
            </div>

            <div className={styles?.actions}>
              <p>Last report generated on: June 1, 2021</p>

              <Button onClick={handleGenerate}>
                <ClipboardSVG />
                <p>GENERATE REPORT</p>
              </Button>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={4} className='mb-1'>
            <div className={styles?.deliveries}>
              <p>
                COMPLETED DELIVERIES
                <span>
                  {dashboardData?.deliveries?.overall_completed} <MotorCycleSVG />
                </span>
              </p>

              <p>
                ONGOING DELIVERIES
                <span>
                  {dashboardData?.deliveries?.overall_ongoing} <MotorCycleSVG />
                </span>
              </p>
            </div>
          </Col>
          <Col sm={8}>
            <div className={styles?.currentDeliveries}>
              <div className={styles?.header}>
                <h5>CURRENT DELIVERIES</h5>

                <Button onClick={getDashboardData}>
                  <RefreshSVG />
                  <p>REFRESH DELIVERIES</p>
                </Button>
              </div>

              <div className={styles?.cardDatas}>
                <div className={`${styles?.card} ${styles?.pending}`}>
                  <h6>Pending Deliveries</h6>
                  <p>
                    {dashboardData?.deliveries?.pending} <ArrowRightSVG />{' '}
                    <span>Pending Deliveries</span>
                  </p>

                  <div className={styles?.progress}>
                    <ProgressBar
                      className={styles?.pendingBar}
                      now={dashboardData?.deliveries?.percent_pending}
                    />
                    <label>{dashboardData?.deliveries?.percent_pending}</label>
                  </div>
                </div>

                <div className={`${styles?.card} ${styles?.completed}`}>
                  <h6>COMPLETED DELIVERIES</h6>

                  <p>
                    {dashboardData?.deliveries?.completed} <ArrowRightSVG />{' '}
                    <span>Completed Deliveries</span>
                  </p>

                  <div className={styles?.progress}>
                    <ProgressBar
                      className={styles?.completedBar}
                      now={dashboardData?.deliveries?.percent_completed}
                    />
                    <label>{dashboardData?.deliveries?.percent_completed}</label>
                  </div>
                </div>

                <div className={`${styles?.card} ${styles?.cancelled}`}>
                  <h6>CANCELLED DELIVERIES</h6>

                  <p>
                    {dashboardData?.deliveries?.cancelled} <ArrowRightSVG />{' '}
                    <span>Cancelled Deliveries</span>
                  </p>

                  <div className={styles?.progress}>
                    <ProgressBar
                      className={styles?.cancelledBar}
                      now={dashboardData?.deliveries?.percent_cancelled}
                    />
                    <label>{dashboardData?.deliveries?.percent_cancelled}</label>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col sm={4} className='mb-1'>
            <div className={styles?.merchantList}>
              <div className={styles?.merchantHeader}>
                <p>
                  LIST OF MERCHANTS <span>Merchant List</span>
                </p>

                <MerchantSVG onClick={handleGotoMerchant} />
              </div>

              <div className={styles?.list}>
                {merchantList?.map((dd) => {
                  return (
                    <div key={dd?.id} className={styles?.data}>
                      <div className={styles?.image}>
                        <ImageUI src={dd?.image} fallbackSrc={sampleImage} />
                      </div>
                      <div className={styles?.body}>
                        <p>
                          {dd?.name} <span>{dd?.available} Available Products</span>
                        </p>

                        <ArrowRightSVG onClick={() => handleMerchantSelect(dd?.id)} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col sm={8}>
            <div className={styles?.chart}>
              <div className={styles?.header}>
                <p>
                  DELIVERY PERCENTAGE <span>Percentage of deliveries</span>
                </p>

                <div className={styles?.selectContainer}>
                  <Form.Select value={overview} className={styles?.select} onChange={handleFilter}>
                    <option value='Month'>Monthly View</option>
                    <option value='Year'>Yearly View</option>
                  </Form.Select>
                </div>
              </div>

              <BarChart options={options} data={barData} />
            </div>
          </Col>
        </Row>
      </Container>

      <PasswordPrompt />
    </PrivateLayout>
  );
};

const options = {
  stacked: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: 'linear',
      display: true,
      position: 'left',
      grid: {
        display: false,
      },
    },
    y1: {
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        display: false,
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
};

export default Dashboard;
