/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUI/FormText';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { generateReport, getMerchantsState } from 'reducers/merchantReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

// Main component
const GenerateReport = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loginStoreState = useSelector(selectAuth);
  const { user_type, userInfo } = loginStoreState;

  const merchantState = useSelector(getMerchantsState);
  const { generateLogs } = merchantState;

  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
  });

  const handleCancel = () => {
    navigate(-1);
  };

  const handleGenerate = async () => {
    const saveData = {
      to: formData?.endDate,
      from: formData?.startDate,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.warning('Please fill in the empty fields');
      return;
    }

    const apiData = new FormData();

    apiData.append('to', saveData?.to);
    apiData.append('from', saveData?.from);

    if (user_type === 'merchant') {
      apiData.append('merchant_id', userInfo?.id);
    }

    try {
      const response = await dispatch(generateReport(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        console.log('response', response);

        const url = window.URL.createObjectURL(new Blob([response.payload]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reports.csv');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        toast.info('Successfully Downloaded File');

        setFormData({
          startDate: '',
          endDate: '',
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <PrivateLayout pageTitle='Generate Report'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>GENERATE REPORT</h4>
              <p>
                <span>DASHBOARD</span> / GENERATE REPORT
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addProduct}>
              <p className={styles?.productHeader}>
                GENERATE REPORT <span>Generate Report Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    type='date'
                    name='Start Date'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Start Date'}
                    stateValue={formData?.startDate}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='date'
                    name='End Date'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Start Date'}
                    stateValue={formData?.endDate}
                  />
                </div>
              </div>

              <div className={styles?.actions}>
                <Button
                  className={styles?.add}
                  onClick={handleGenerate}
                  disabled={generateLogs?.isLoading}
                >
                  <PlusSVG />
                  GENERATE REPORT
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default GenerateReport;
