/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Image } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import { findRider, skipRider } from 'reducers/riderReducer';
import { cancelOrder, acceptOrder, getOrdersState } from 'reducers/orderReducer';
import ConfirmActionWithRemarks from 'components/GlobalUI/Modal/ConfirmActionWithRemarks/ConfirmActionWithRemarks';

import styles from './index.module.scss';
import logo from 'assets/images/knelogo.png';
import location from 'assets/images/LOCATION.png';
import AddressOnMap from 'components/GlobalUI/Address';
import sampleImage from 'assets/images/placeholder.png';
import orderDetails from 'assets/images/order Details.png';
import orderSummary from 'assets/images/white order summary.png';

import { ReactComponent as Close } from 'assets/svg/order_close.svg';
import { ReactComponent as Accept } from 'assets/svg/order_accept.svg';
import { ReactComponent as CloseModal } from 'assets/svg/modal_close.svg';
import { ReactComponent as OrderDetailsSVG } from 'assets/svg/order_details.svg';

const OrderDetailsModal = ({ modalData, setModalData, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const orderState = useSelector(getOrdersState);
  const { cancelLogs, acceptLogs } = orderState;

  if (!userInfo) return;

  const [screen, showScreen] = useState(1);
  const [tableData, setTableData] = useState(null);
  const [searchTimes, setSearchTimes] = useState(1);
  const [riderLoading, setRiderLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const onHide = () => {
    setModalData(null);
    setTableData(null);
    setShowModal(false);
    localStorage.removeItem('order-id');
  };

  const handleOpenModal = (data) => {
    setShowModal(false);

    setTimeout(() => {
      setModalData(data);
      setShowModal(true);
    }, 500);
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  const handleCancelOrder = async (data, reason) => {
    const apiData = new FormData();

    apiData.append('order_id', data?.id);
    apiData.append('reason', reason);

    try {
      const response = await dispatch(cancelOrder(apiData));

      if (response) {
        const customerID = tableData?.included?.customer_details?.id;
        const orderID = data?.id;

        fetch(
          `https://klickneat.ksmiguel.com/pusher/send-event.php?channel=customer-channel-${customerID}&event=cancel-order&data={"order-id":${orderID},"reason":"${reason}"}`,
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            return response.json();
          })
          .then((data) => {
            toast.info('Successfully cancelled order');
            onHide();
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleAcceptOrder = async () => {
    const apiData = new FormData();

    apiData.append('order_id', tableData?.id);

    try {
      const response = await dispatch(acceptOrder(apiData));

      if (response) {
        const customerID = tableData?.included?.customer_details?.id;
        const orderID = tableData?.id;

        fetch(
          `https://klickneat.ksmiguel.com/pusher/send-event.php?channel=customer-channel-${customerID}&event=accept-order&data={"order-id":${orderID}}`,
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            return response.json();
          })
          .then((data) => {
            toast.info('Successfully accepted order');
            getOrderDetails();
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFindRider = async () => {
    setRiderLoading(true);

    const apiData = new FormData();
    apiData.append('order_id', tableData?.id);
    apiData.append('search_counter', searchTimes);

    try {
      const response = await dispatch(findRider(apiData));

      if (response) {
        if (response?.payload?.data?.length === 0) {
          toast.warning(`No rider found, click find rider to find further away rider`);
          setRiderLoading(false);

          const searchCounter = 1 + +searchTimes;
          setSearchTimes(searchCounter);

          return;
        }

        const riderID = response?.payload?.data[0];

        fetch(
          `https://klickneat.ksmiguel.com/pusher/send-event.php?channel=rider-channel-${riderID}&event=incoming-order&data={"order-id":${tableData?.id},"merchant-id":${userInfo?.id}}`,
          // `https://klickneat.ksmiguel.com/pusher/send-event.php?channel=rider-channel-8&event=incoming-order&data={"order-id":${tableData?.id},"merchant-id":${userInfo?.id}}`,
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            return response.json();
          })
          .then((data) => {
            toast.info('Found rider, waiting for reply...');
            getOrderDetails();

            setTimeout(() => {
              if (!orderSuccess) {
                handleSkipRider(tableData?.id, riderID);

                toast.warning(`Rider didn't respond, click the button to find rider.`);
                setRiderLoading(false);

                const searchCounter = 1 + +searchTimes;
                setSearchTimes(searchCounter);
              }
            }, 60000);
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSkipRider = async (orderId, riderID) => {
    const formData = new FormData();

    formData.append('order_id', orderId);
    formData.append('rider_id', riderID);

    try {
      const result = await dispatch(skipRider(formData));

      if (result) {
        toast.success(`Successfully filtered out rider.`);
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const getOrderDetails = async () => {
    const id = modalData[`order-id`] || modalData?.id;

    try {
      axios
        .get(
          `api/transactions/${id}?include[0]=customer&include[1]=items&include[2]=merchant&include[3]=rider`,
        )
        .then((response) => {
          setTableData(response?.data?.data);
        });
    } catch (error) {
      // console.log('Error', error);
    }
  };

  useEffect(() => {
    const handleOrderEvent = (data) => {
      console.log('data', data);

      // Handle the event data here
      if (data) {
        toast.info('New Order, Click to view.', {
          position: 'bottom-right',
          autoClose: false,
          closeOnClick: true,
          onClick: () => handleOpenModal(data),
        });
      }
    };

    const handleCancelEvent = (data) => {
      // Handle the event data here
      if (data) {
        // if (+data['order-id'] === +modalData[`order-id`]) {
        toast.warning(`Rider ${data['rider-id']} cancelled the order. Will find new rider`);
        handleFindRider();
        // }
      }
    };

    const handleAcceptEvent = (data) => {
      // Handle the event data here
      const orderID = localStorage.getItem('order-id');

      if (data) {
        if (+data['order-id'] === +orderID) {
          toast.success(
            `Rider ${data['rider-id']} accepted the order. check details on order page`,
          );
          onHide();
          setOrderSuccess(true);
          setRiderLoading(false);
          // getOrderDetails();
        }
      }
    };

    const channel = pusher.subscribe(`merchant-channel-${userInfo?.id}`);

    channel.bind('create-order', handleOrderEvent);
    channel.bind('cancel-order', handleCancelEvent);
    channel.bind('accept-order', handleAcceptEvent);

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind('create-order', handleOrderEvent);
      channel.unbind('cancel-order', handleCancelEvent);
      channel.unbind('accept-order', handleAcceptEvent);
      pusher.unsubscribe(`merchant-channel-${userInfo?.id}`);
    };
  }, []);

  useEffect(() => {
    if (showModal && modalData) {
      getOrderDetails();
      localStorage.setItem('order-id', modalData?.id);
    }
  }, [showModal, modalData]);

  useEffect(() => {
    if (userInfo && tableData) {
      if (tableData?.distance) return;

      fetch(
        `https://ksmiguel.com/klickneat/computeDistance.php?originLat=${userInfo?.latitude}&originLng=${userInfo?.longitude}&destinationLat=${tableData?.latitude}&destinationLng=${tableData?.longitude}`,
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response.json();
        })
        .then((data) => {
          const kilometer = +data?.distanceMeters / 1000;

          setTableData((prev) => ({
            ...prev,
            distance: kilometer,
          }));
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    }
  }, [tableData, userInfo]);

  console.log('tableData', tableData);

  return (
    <>
      <Modal
        size='lg'
        centered
        onHide={onHide}
        show={showModal}
        className={styles?.orderDetails}
        aria-labelledby='order-created'
      >
        <Modal.Body className={styles?.body}>
          <div className={styles?.leftContainer}>
            {screen && screen === 1 && (
              <div className={styles?.orderDetails}>
                <div className={styles?.header}>
                  <OrderDetailsSVG />
                  <p>Order Details</p>
                </div>

                <div className={styles?.orderDetailsBody}>
                  {tableData?.included?.items?.map((dd, idx) => {
                    return (
                      <div key={idx} className={styles?.list}>
                        <p className={styles?.number}>#{+idx + 1}</p>

                        <Image src={dd?.imgpath} className={styles?.image} />

                        <div className={styles?.title}>
                          <h5>
                            ({dd?.code}) {dd?.name}
                          </h5>

                          <div className={styles?.addOns}>
                            {dd?.subProduct &&
                              dd?.subProduct.length >= 1 &&
                              dd?.subProduct.map((sub, index) => {
                                return (
                                  <div key={index} className={styles?.subList}>
                                    <h6>{sub?.name}</h6>

                                    <ul>
                                      {sub?.data &&
                                        sub?.data.length >= 1 &&
                                        sub?.data?.map((subData, subIndex) => {
                                          return <li key={subIndex}>{subData}</li>;
                                        })}
                                    </ul>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        <p className={styles?.quantity}>x{dd?.quantity}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {screen && screen === 2 && (
              <div className={styles?.addressOnMap}>
                <AddressOnMap lat={+tableData?.latitude} lng={+tableData?.longitude} />
              </div>
            )}

            <div className={styles?.footer}>
              {tableData && tableData.status === 'pending' && (
                <>
                  <Button
                    onClick={() =>
                      setShowConfirm({
                        data: tableData,
                        show: true,
                        submit: handleCancelOrder,
                        type: 'cancelling of order?',
                      })
                    }
                    className={styles?.cancel}
                    disabled={cancelLogs?.isLoading}
                  >
                    <Close />
                    CANCEL ORDER
                  </Button>
                  <Button
                    className={styles?.accept}
                    onClick={handleAcceptOrder}
                    disabled={acceptLogs?.isLoading}
                  >
                    <Accept />
                    ACCEPT ORDER
                  </Button>
                </>
              )}
              {tableData && tableData.status === 'preparing' && (
                <Button
                  disabled={riderLoading}
                  onClick={handleFindRider}
                  className={styles?.accept}
                >
                  <Accept />
                  Find Rider
                </Button>
              )}
              {tableData && tableData.status === 'prepared' && (
                <p className={styles?.prepared}>Delivery in Progress...</p>
              )}

              {tableData && tableData.status === 'rider-to-customer' && (
                <p className={styles?.prepared}>Delivery in Progress...</p>
              )}

              {tableData && tableData.status === 'delivered' && (
                <p className={styles?.prepared}>Successfully Delivered</p>
              )}
            </div>

            <div className={styles?.selection}>
              <div className={styles?.data}>
                <div
                  onClick={() => showScreen(1)}
                  className={`${styles?.image} ${screen === 1 ? styles?.active : ''}`}
                >
                  <Image src={orderDetails} />
                </div>

                <p>ORDER DETAILS</p>
              </div>

              <div className={styles?.data}>
                <div
                  onClick={() => showScreen(2)}
                  className={`${styles?.image} ${screen === 2 ? styles?.active : ''}`}
                >
                  <Image src={location} />
                </div>

                <p>LOCATION</p>
              </div>
            </div>
          </div>

          <div className={styles?.rightContainer}>
            <CloseModal onClick={onHide} className={styles?.closemodal} />

            <div className={styles?.orderHeader}>
              <Image src={logo} />

              <p>INCOMING ORDER</p>
            </div>

            <div className={styles?.customer}>
              <Image src={sampleImage} />
              <h3>{`${tableData?.included?.customer_details?.first_name} ${tableData?.included?.customer_details?.last_name}`}</h3>
              <h4>{tableData?.included?.customer_details?.mobile_number}</h4>
              <p>Email: {tableData?.included?.customer_details?.email}</p>
              <div className={styles?.flexGroup}>
                <p>Order ID: {tableData?.id}</p>
                <p>Status: {tableData?.status}</p>
              </div>
              <p>Rider: {tableData?.rider ? tableData?.rider : 'No Rider'}</p>
              <p>Rider #: {tableData?.included?.rider_details?.contact_no}</p>

              <p>Date: {tableData?.created?.datetime}</p>

              <p>{tableData?.included?.customer_details?.address}</p>

              <p className={styles?.distance}>{tableData?.distance}km away</p>
            </div>

            <hr className={styles?.line} />

            <div className={styles?.orderSummary}>
              <div className={styles?.summaryHeader}>
                <Image src={orderSummary} />
                <p>
                  Order Summary <span>{tableData?.created?.string}</span>
                </p>
              </div>

              <div className={styles?.summaryBody}>
                <div className={styles?.row}>
                  <p>Order</p>
                  <p>PHP {tableData?.subtotal}</p>
                </div>

                <div className={styles?.row}>
                  <p>Delivery</p>
                  <p>PHP {tableData?.delivery_fee}</p>
                </div>

                <div className={styles?.row}>
                  <p>Promo Discount</p>
                  <p>PHP -{tableData?.discount}</p>
                </div>

                <hr className={styles?.summaryTotalLine} />

                <div className={styles?.total}>
                  <p>Total</p>
                  <p>PHP {tableData?.totalAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmActionWithRemarks
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </>
  );
};

const pusher = new Pusher('490ce039becb2a8b6a9d', {
  cluster: 'ap1',
});

export default OrderDetailsModal;
