/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { getPromos, createPromo, getPromo, updatePromo, deletePromo } from './thunks/promosThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  createLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
  singleData: null,
  singleIncluded: null,
  singleLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'promos',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getPromos.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getPromos.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getPromos.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [createPromo.fulfilled]: (state, action) => {
      state.createLogs.isSuccess = true;
      state.createLogs.isLoading = false;
    },
    [createPromo.pending]: (state) => {
      state.createLogs.error = '';
      state.createLogs.isFailed = false;
      state.createLogs.isLoading = true;
      state.createLogs.isSuccess = false;
    },
    [createPromo.rejected]: (state, action) => {
      state.createLogs.isFailed = true;
      state.createLogs.isLoading = false;
      state.createLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getPromo.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
      state.singleIncluded = action.payload.included;
    },
    [getPromo.pending]: (state) => {
      state.singleLogs.error = '';
      state.singleLogs.isFailed = false;
      state.singleLogs.isLoading = true;
      state.singleLogs.isSuccess = false;
    },
    [getPromo.rejected]: (state, action) => {
      state.singleLogs.isFailed = true;
      state.singleLogs.isLoading = false;
      state.singleLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updatePromo.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updatePromo.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updatePromo.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deletePromo.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deletePromo.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deletePromo.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: promosActions, reducer: promosReducer } = slice;

export const { resetState } = promosActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.promos || initialState;

export const getpromosState = createSelector([selectDomain], (promosState) => promosState);

/* --------------------------------------------------------------------------------- */

export { getPromos, createPromo, getPromo, updatePromo, deletePromo };
