// Import core & vendor packages below
import { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import { handleTextChange } from 'helpers/handleTextChange';

// Assets
import '../Styles/FormSelection.scss';
import { ReactComponent as EyeIcon } from 'assets/svg/eye.svg';
import { ReactComponent as HideIcon } from 'assets/svg/hide.svg';

// Main component
const FormSelection = ({
  type,
  name,
  options,
  setState,
  stateValue,
  haveClass = '',
  noLabel = false,
  placeholder = '',
  ...rest
}) => {
  const [formType, setFormType] = useState();

  const handleShowPassword = () => {
    setFormType('text');
  };

  const handleHidePassword = () => {
    setFormType('password');
  };

  useEffect(() => {
    if (type) {
      setFormType(type);
    }
  }, [type]);

  return (
    <Form.Group className={`${haveClass} global-form-input`}>
      {!noLabel && <Form.Label>{name}</Form.Label>}

      <Form.Select
        {...rest}
        type={formType}
        value={stateValue}
        name={camelize(name)}
        onChange={handleTextChange(setState)}
      >
        {placeholder !== '' ? (
          <option value='' hidden>
            {placeholder}
          </option>
        ) : (
          <option value='' hidden>
            - Select -
          </option>
        )}

        {options &&
          options.length > 0 &&
          options.map((data, idx) => {
            return (
              <option key={idx} value={data?.value}>
                {data?.label}
              </option>
            );
          })}
      </Form.Select>

      {formType === 'password' && (
        <EyeIcon
          className='eye-icon'
          onMouseUp={handleHidePassword}
          onMouseDown={handleShowPassword}
        />
      )}

      {formType === 'text' && name.includes('Password') && (
        <HideIcon
          className='eye-icon hide'
          onMouseUp={handleHidePassword}
          onMouseDown={handleShowPassword}
        />
      )}
    </Form.Group>
  );
};

export default FormSelection;
