/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BarChart = ({ options, data }) => {
  return <Line options={options} data={data} />;
};

export default BarChart;
