/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getProduct = createAsyncThunk('getProduct', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/merchant-products/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getProducts = createAsyncThunk(
  'getProducts',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/merchant-product-list/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createProduct = createAsyncThunk(
  'createProduct',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: params,
      url: `api/merchant-products`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateProduct = createAsyncThunk(
  'updateProduct',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/merchant-products/${id}?_method=PUT`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteSubProduct = createAsyncThunk(
  'deleteSubProduct',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/delete-sub-product`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const toggleAvailability = createAsyncThunk(
  'toggleAvailability',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/product-update-status`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteProduct = createAsyncThunk('deleteProduct', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/merchant-products/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

// export const getMerchant = createAsyncThunk(
//   'getMerchant',
//   async (id, { rejectWithValue }) => {
//     return await axios({
//       method: 'get',
//       url: `api/merchants/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const samplePutAPI = createAsyncThunk(
//   'samplePutAPI',
//   async (params, { rejectWithValue }) => {
//     return await axios({
//       method: 'put',
//       url: `sample-url`,
//       data: params,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );
