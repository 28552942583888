import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from 'reducers/authReducer';
import { ridersReducer } from 'reducers/riderReducer';
import { ordersReducer } from 'reducers/orderReducer';
import { sampleReducer } from 'reducers/sampleReducer';
import { promosReducer } from 'reducers/promosReducer';
import { productReducer } from 'reducers/productReducer';
import { merchantsReducer } from 'reducers/merchantReducer';
import { transactionsReducer } from 'reducers/transactionsReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    riders: ridersReducer,
    promos: promosReducer,
    sample: sampleReducer,
    orders: ordersReducer,
    product: productReducer,
    merchants: merchantsReducer,
    transactions: transactionsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
