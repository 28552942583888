/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Core & Vendor Packages
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Navbar, Container, OverlayTrigger, Popover, Row, Col, Button, Nav } from 'react-bootstrap';

// Components
import { logout } from 'reducers/authReducer';
import ImageUI from 'components/GlobalUI/ImageUI';
import { selectAuth } from 'reducers/authReducer';

// Helpers
import { removeItem } from 'utils/storage';

// Assets
import Logo from 'assets/images/logo.png';
import 'components/Styles/UserAccount.scss';
import avatar from 'assets/images/avatar.jpg';
import PlaceholderLogo from 'assets/images/placeholder.png';
import { ReactComponent as ListSVG } from 'assets/svg/list.svg';
import { ReactComponent as UserSVG } from 'assets/svg/user.svg';
import { ReactComponent as LogoutSVG } from 'assets/svg/logout.svg';
import { ReactComponent as DashboardSVG } from 'assets/svg/Dashboard.svg';
import { ReactComponent as TransactionSVG } from 'assets/svg/receipt.svg';
import { ReactComponent as MerchantSVG } from 'assets/svg/store logo.svg';
import { ReactComponent as MotorSVG } from 'assets/svg/motor.svg';

// Main Component
const NavBar = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const loginStoreState = useSelector(selectAuth);
  const { user_type } = loginStoreState;

  const logOut = () => {
    removeItem('accessToken');

    setTimeout(() => {
      dispatch(logout());
      navigate('/');
    }, 100);
  };

  const audit = pathname.includes('/audit') ? 'active' : '';
  const promos = pathname.includes('/promos') ? 'active' : '';
  const orders = pathname.includes('/orders') ? 'active' : '';
  const riders = pathname.includes('/riders') ? 'active' : '';
  const accounts = pathname.includes('/accounts') ? 'active' : '';
  const products = pathname.includes('/products') ? 'active' : '';
  const dashboard = pathname.includes('/dashboard') ? 'active' : '';
  const merchants = pathname.includes('/merchants') ? 'active' : '';
  const transactions = pathname.includes('/transactions') ? 'active' : '';

  return (
    <>
      <Navbar className='bg-body-tertiary top-bar'>
        <Container>
          <Navbar.Brand className='brand-container'>
            <ImageUI src={Logo} fallbackSrc={PlaceholderLogo} />
            <p>KLICK N EAT</p>
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse className='justify-content-end'>
            <div className='user-account'>
              <p>WELCOME, {user_type}</p>

              <ImageUI fallbackSrc={avatar} src={avatar} />

              <LogoutSVG onClick={logOut} />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar expand='lg' className='bg-body-tertiary red-bar'>
        <Container>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />

          <Navbar.Collapse id='navbar-nav'>
            <Nav className='me-auto'>
              {user_type === 'admin' ? (
                <>
                  <Nav.Link as={Link} to='/dashboard' className={dashboard}>
                    <DashboardSVG />
                    Dashboard
                  </Nav.Link>
                  <Nav.Link as={Link} to='/transactions' className={transactions}>
                    <TransactionSVG />
                    Transactions
                  </Nav.Link>
                  <Nav.Link as={Link} to='/promos' className={promos}>
                    <TransactionSVG />
                    Promos
                  </Nav.Link>
                  <Nav.Link as={Link} to='/merchants' className={merchants}>
                    <MerchantSVG />
                    MERCHANTS
                  </Nav.Link>
                  <Nav.Link as={Link} to='/riders' className={riders}>
                    <MotorSVG />
                    RIDERS
                  </Nav.Link>
                  {/* <Nav.Link as={Link} to='/accounts' className={accounts}>
                    <UserSVG />
                    Accounts
                  </Nav.Link> */}
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to='/products' className={products}>
                    <ListSVG />
                    Products
                  </Nav.Link>
                  <Nav.Link as={Link} to='/orders' className={orders}>
                    <ListSVG />
                    Orders
                  </Nav.Link>

                  <Nav.Link as={Link} to='/audit' className={audit}>
                    <UserSVG />
                    Audit
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
