/* eslint-disable newline-per-chained-call */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Popover,
  Dropdown,
  Container,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import OrderDetailsModal from './OrderDetailsModal/OrderDetails';
import TablePagination from 'components/GlobalUI/TablePagination';
import { getTransactions, getTransactionsState } from 'reducers/transactionsReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as ClipboardSVG } from 'assets/svg/clipboard.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

const sampleData = [
  {
    id: 1,
    customerName: 'Juan Dela Cruz',
    merchantName: 'Merchant 1',
    riderName: 'John Santos',
    orderTotal: 'Php 1,000.00',
    status: 'Completed',
  },
  {
    id: 2,
    customerName: 'Jane Dela Cruz',
    merchantName: 'Merchant 2',
    riderName: 'Carl Santos',
    orderTotal: 'Php 1,000.00',
    status: 'Completed',
  },
  {
    id: 3,
    customerName: 'Mark Dela Cruz',
    merchantName: 'Merchant 3',
    riderName: 'Chris Santos',
    orderTotal: 'Php 1,000.00',
    status: 'Completed',
  },
  {
    id: 4,
    customerName: 'John Dela Cruz',
    merchantName: 'Merchant 4',
    riderName: 'Arthur Santos',
    orderTotal: 'Php 1,000.00',
    status: 'Cancelled',
  },
  {
    id: 5,
    customerName: 'Mike Dela Cruz',
    merchantName: 'Merchant 5',
    riderName: 'Jake Santos',
    orderTotal: 'Php 1,000.00',
    status: 'Cancelled',
  },
];

// Main component
const Transactions = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef(null);

  const transactionState = useSelector(getTransactionsState);
  const { meta, logs } = transactionState;

  const [tableData, setTableData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [filter, setFilter] = useState({
    to: '',
    page: 1,
    from: '',
    search: '',
    status: '',
    per_page: 5,
  });

  const handleGenerate = () => {
    navigate('/dashboard/generate-report');
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      to: filter?.to,
      from: filter?.from,
      search: filter?.search,
      status: filter?.status,
      ['include[0]']: 'items',
      ['include[1]']: 'customer',
      ['include[2]']: 'merchant',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleFilter = (filterBy) => {
    const params = {
      status: '',
      per_page: 5,
      to: filter?.to,
      from: filter?.from,
      page: filter?.page,
      search: filter?.search,
      ['include[0]']: 'items',
      ['include[1]']: 'customer',
      ['include[2]']: 'merchant',
    };

    switch (filterBy) {
      case 'all':
        setFilter((prev) => ({
          ...prev,
          status: '',
        }));

        const allParams = objectCleaner({
          ...params,
          status: '',
        });

        tryCatch(allParams);
        break;

      case 'pending':
        setFilter((prev) => ({
          ...prev,
          status: 'pending',
        }));

        const pendingParams = objectCleaner({
          ...params,
          status: 'pending',
        });

        tryCatch(pendingParams);
        break;
      case 'preparing':
        setFilter((prev) => ({
          ...prev,
          status: 'preparing',
        }));

        const preparingParams = objectCleaner({
          ...params,
          status: 'preparing',
        });

        tryCatch(preparingParams);
        break;
      case 'cancelled':
        setFilter((prev) => ({
          ...prev,
          status: 'cancelled',
        }));

        const cancelledParams = objectCleaner({
          ...params,
          status: 'cancelled',
        });

        tryCatch(cancelledParams);
        break;
      case 'rider-to-customer':
        setFilter((prev) => ({
          ...prev,
          status: 'rider-to-customer',
        }));

        const riderToCustomerParams = objectCleaner({
          ...params,
          status: 'rider-to-customer',
        });

        tryCatch(riderToCustomerParams);
        break;
      case 'delivered':
        setFilter((prev) => ({
          ...prev,
          status: 'delivered',
        }));

        const deliveredParams = objectCleaner({
          ...params,
          status: 'delivered',
        });

        tryCatch(deliveredParams);
        break;
      default:
        break;
    }
  };

  const handleTransactions = () => {
    const unfinalParams = {
      to: filter?.to,
      from: filter?.from,
      page: filter?.page,
      search: filter?.search,
      status: filter?.status,
      per_page: filter?.per_page,
      ['include[0]']: 'items',
      ['include[1]']: 'customer',
      ['include[2]']: 'merchant',
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const tryCatch = async (params) => {
    setTableData([]);

    try {
      const response = await dispatch(getTransactions(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          status: dd?.status,
          riderName: dd?.rider,
          customerName: dd?.customer,
          merchantName: dd?.merchant,
          orderTotal: dd?.totalAmount,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleDates = (e) => {
    const { name, value } = e.target;

    const _dateRange = {
      to: filter?.to,
      from: filter?.from,
    };

    _dateRange[name] = value;

    setFilter((prev) => ({ ...prev, [name]: value }));

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      if (_dateRange?.from !== '' && _dateRange?.to !== '') {
        setFilter((prev) => ({ ...prev, page: 1 }));

        const newObj = {
          page: 1,
          to: _dateRange?.to,
          from: _dateRange?.from,
          search: filter?.search,
          status: filter?.status,
          per_page: filter?.per_page,
          ['include[0]']: 'items',
          ['include[1]']: 'customer',
          ['include[2]']: 'merchant',
        };

        const params = objectCleaner(newObj);
        tryCatch(params);
      }
    }, 1000);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        to: filter?.to,
        from: filter?.from,
        status: filter?.status,
        per_page: filter?.per_page,
        ['include[0]']: 'items',
        ['include[1]']: 'customer',
        ['include[2]']: 'merchant',
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  useEffect(() => {
    handleTransactions();
  }, []);

  return (
    <PrivateLayout pageTitle='Transactions'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>TRANSACTION HISTORY</h4>
              <p>
                <span>TRANSACTIONS</span> / TRANSACTION HISTORY
              </p>
            </div>

            <div className={styles?.actions}>
              <Form.Group className={styles?.group}>
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type='date'
                  value={filter?.from}
                  name='from'
                  onChange={handleDates}
                  max={new Date().toISOString().slice(0, 10)}
                />
              </Form.Group>

              <Form.Group className={styles?.group}>
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type='date'
                  value={filter?.to}
                  name='to'
                  onChange={(e) => {
                    if (filter?.from !== '') {
                      handleDates(e);
                      return;
                    }

                    toast.warning('Please select from first');
                  }}
                />
              </Form.Group>

              <Button onClick={handleGenerate}>
                <ClipboardSVG />
                <p>GENERATE REPORT</p>
              </Button>

              <Dropdown>
                <Dropdown.Toggle variant='success' className={styles?.filter}>
                  {filter?.status ? filter?.status : 'Select Status'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {filter?.status !== '' && (
                    <Dropdown.Item onClick={() => handleFilter('all')}>All</Dropdown.Item>
                  )}

                  {filter?.status !== 'pending' && (
                    <Dropdown.Item onClick={() => handleFilter('pending')}>Pending</Dropdown.Item>
                  )}

                  {filter?.status !== 'preparing' && (
                    <Dropdown.Item onClick={() => handleFilter('preparing')}>
                      Preparing
                    </Dropdown.Item>
                  )}

                  {filter?.status !== 'cancelled' && (
                    <Dropdown.Item onClick={() => handleFilter('cancelled')}>
                      Cancelled
                    </Dropdown.Item>
                  )}

                  {filter?.status !== 'rider-to-customer' && (
                    <Dropdown.Item onClick={() => handleFilter('rider-to-customer')}>
                      Rider to Customer
                    </Dropdown.Item>
                  )}

                  {filter?.status !== 'delivered' && (
                    <Dropdown.Item onClick={() => handleFilter('delivered')}>
                      Delivered
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                TRANSACTION HISTORY LIST<span>List of Transactions</span>
              </p>

              <Table responsive striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th>Merchant Name</th>
                    <th>Rider Name</th>
                    <th>Order Total</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((dd) => (
                      <tr key={dd?.id}>
                        <td>{dd?.customerName}</td>
                        <td>{dd?.merchantName}</td>
                        <td>{dd?.riderName}</td>
                        <td>{dd?.orderTotal}</td>
                        <td>
                          <span className={styles[`${dd?.status}`]}>{dd?.status}</span>
                        </td>
                        <td>
                          <OverlayTrigger
                            trigger='click'
                            placement='left'
                            rootClose
                            overlay={
                              <Popover id='popover-table-action'>
                                <Popover.Body className='d-flex justify-content-between p-0'>
                                  <div className='button-actions'>
                                    <Button
                                      onClick={() => {
                                        navigate(`/transactions/${dd.id}`, { state: { dd } });
                                      }}
                                      className='delete'
                                    >
                                      View Details
                                    </Button>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant='secondary'
                              className='popoverButton bg-transparent p-0 border-0'
                            >
                              <ActionSVG className={styles?.action} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
              </Table>

              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <OrderDetailsModal
        modalData={modalData}
        showModal={showModal}
        setModalData={setModalData}
        setShowModal={setShowModal}
      />
    </PrivateLayout>
  );
};

export default Transactions;
