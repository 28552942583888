/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import FormSelection from 'components/GlobalUI/FormSelection';
import { createProduct, getProductState } from 'reducers/productReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';
import { ReactComponent as AddRowSVG } from 'assets/svg/plusSign.svg';
import { ReactComponent as RemoveRow } from 'assets/svg/closeButton.svg';

const initialState = {
  image: '',
  price: '',
  foodCode: '',
  itemName: '',
  category: '',
  inventory: '',
  description: '',
  subProducts: [
    {
      id: uuidv4(),
      type: '',
      price: '',
      productName: '',
    },
  ],
};

// Main component
const AddProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const productState = useSelector(getProductState);
  const { createLogs } = productState;

  const [categories, setCategories] = useState([]);

  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate('/products');
  };

  const handleTableChange = (e, id) => {
    const { name, value } = e.target;

    const newData = formData?.subProducts?.map((dd) => {
      if (dd?.id === id) {
        return {
          ...dd,
          [name]: value,
        };
      }

      return dd;
    });

    setFormData((prev) => ({
      ...prev,
      subProducts: newData,
    }));
  };

  const addRow = () => {
    const newData = {
      id: uuidv4(),
      type: '',
      price: '',
      productName: '',
    };

    const addedData = formData?.subProducts?.concat([newData]);

    setFormData((prev) => ({
      ...prev,
      subProducts: addedData,
    }));
  };

  const removeRow = (id) => {
    const newData = formData?.subProducts?.filter((dd) => dd?.id !== id);

    setFormData((prev) => ({
      ...prev,
      subProducts: newData,
    }));
  };

  const handleSave = async () => {
    const saveData = {
      type: formData?.type,
      photo: formData?.image,
      price: formData?.price,
      name: formData?.itemName,
      code: formData?.foodCode,
      merchant_id: userInfo?.id,
      inventory: formData?.inventory,
      category: formData?.category,
      description: formData?.description,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData, ['inventory']);

    if (saveData?.type === 'Others' && saveData?.inventory === '') {
      toast.warning('Please fill in the empty fields');
      return;
    }

    if (emptyCheck) {
      toast.warning('Please fill in the empty fieldssss');
      return;
    }

    const apiData = new FormData();

    apiData.append('name', saveData?.name);
    apiData.append('code', saveData?.code);
    apiData.append('price', saveData?.price);
    apiData.append('photo', saveData?.photo[0]);
    apiData.append('merchant_id', saveData?.merchant_id);
    apiData.append('description', saveData?.description);
    apiData.append('category', saveData?.category?.value);

    for (let index = 0; index < formData?.subProducts.length; index++) {
      const { productName, type, price } = formData.subProducts[index];

      if (productName !== '' && type !== '' && price !== '') {
        apiData.append(`product_variation[${index}][type]`, type);
        apiData.append(`product_variation[${index}][price]`, price);
        apiData.append(`product_variation[${index}][name]`, productName);
      }
    }

    try {
      const response = await dispatch(createProduct(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        const errorArray = [].concat(...Object.values(response?.payload?.errors));

        if (errorArray && errorArray.length > 0) {
          errorArray.forEach((dd) => {
            toast.error(dd);
          });
        }

        return;
      }

      if (response) {
        toast.info('Successfully Created Product');
        setFormData(initialState);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleCategoryChange = (data, actionMeta) => {
    // if (actionMeta.action === 'create-option') {
    //   setFormData((prev) => {
    //     return {
    //       ...prev,
    //       category: data,
    //     };
    //   });
    // } else {
    setFormData((prev) => {
      return {
        ...prev,
        category: data,
      };
    });
    // }
  };

  useEffect(() => {
    const getCategories = async () => {
      try {
        axios.get(`api/merchant-menus/${userInfo?.id}?per_page=1000`).then((response) => {
          setCategories(response?.data?.data);
        });
      } catch (error) {
        // console.log('Error', error);
      }
    };

    if (userInfo) {
      getCategories();
    }
  }, [userInfo]);

  return (
    <PrivateLayout pageTitle='Add Product'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>ADD NEW PRODUCT</h4>
              <p>
                <span>PRODUCTS</span> / ADD NEW PRODUCT
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addProduct}>
              <p className={styles?.productHeader}>
                ADD PRODUCT <span>Enter Product Information Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Photo</span>
                  <ImageUpload name='image' setState={setFormData} stateValue={formData?.image} />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Item Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Item Name'}
                    stateValue={formData?.itemName}
                  />

                  {/* <FormSelection
                    name='type'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter type'}
                    stateValue={formData?.type}
                    options={[
                      { value: 'Food', label: 'Food' },
                      { value: 'Others', label: 'Others' },
                    ]}
                  /> */}

                  {formData?.type === 'Others' && (
                    <FormText
                      type='number'
                      name='Inventory'
                      setState={setFormData}
                      haveClass={styles?.fromGroup}
                      placeholder={'Enter Inventory'}
                      stateValue={formData?.inventory}
                    />
                  )}

                  <FormText
                    as='textarea'
                    type='textarea'
                    name='Description'
                    setState={setFormData}
                    rows={5}
                    placeholder={'Enter Description'}
                    stateValue={formData?.description}
                    haveClass={styles?.fromGroupDescrpition}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='number'
                    name='Price'
                    setState={setFormData}
                    placeholder={'Enter Price'}
                    stateValue={formData?.price}
                    haveClass={styles?.fromGroup}
                  />

                  <FormText
                    type='text'
                    name='Food Code'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Food Code'}
                    stateValue={formData?.foodCode}
                  />

                  <Form.Group className={styles?.group}>
                    <Form.Label>Category</Form.Label>

                    <div className={styles?.selectContainer}>
                      <CreatableSelect
                        styles={stylesOptions}
                        options={categories.map((category) => {
                          return {
                            ...category,
                            value: category?.id,
                            label: category?.name,
                          };
                        })}
                        value={formData?.category}
                        onChange={handleCategoryChange}
                      />
                    </div>
                  </Form.Group>

                  {/* <FormSelection
                    name='Category'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Category'}
                    stateValue={formData?.category}
                    options={categories.map((category) => {
                      return {
                        value: category?.id,
                        label: category?.name,
                      };
                    })}
                  /> */}
                </div>
              </div>

              <div className={styles?.subProducts}>
                {formData?.subProducts &&
                  formData?.subProducts.length >= 1 &&
                  formData?.subProducts.map((dd) => {
                    return (
                      <div key={dd?.id} className={styles?.subProduct}>
                        <Form.Group className={styles?.group}>
                          <Form.Label>Product Name</Form.Label>
                          <Form.Control
                            name='productName'
                            value={dd?.productName}
                            onChange={(e) => {
                              handleTableChange(e, dd?.id);
                            }}
                          />
                        </Form.Group>

                        <Form.Group className={styles?.smallGroup}>
                          <Form.Label>Type</Form.Label>
                          <Form.Control
                            name='type'
                            value={dd?.type}
                            onChange={(e) => {
                              handleTableChange(e, dd?.id);
                            }}
                          />
                        </Form.Group>

                        <Form.Group className={styles?.smallGroup}>
                          <Form.Label>Price</Form.Label>
                          <Form.Control
                            name='price'
                            type='number'
                            value={dd?.price}
                            onChange={(e) => {
                              handleTableChange(e, dd?.id);
                            }}
                          />
                        </Form.Group>

                        <Form.Group className={styles?.buttons}>
                          <AddRowSVG onClick={addRow} />

                          {formData?.subProducts.length > 1 && (
                            <RemoveRow onClick={() => removeRow(dd?.id)} />
                          )}
                        </Form.Group>
                      </div>
                    );
                  })}
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleSave}
                  className={styles?.add}
                  disabled={createLogs?.isLoading}
                >
                  <PlusSVG />
                  ADD NEW PRODUCT
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

const stylesOptions = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    height: '38px',
    border: 'none',
    fontWeight: 400,
    fontSize: '14px',
    borderRadius: '8px',
    background: '#EAEDF0',
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: 400,
    fontSize: '14px',
    background: '#EAEDF0',
  }),
};

export default AddProduct;
