/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getRiders,
  findRider,
  skipRider,
  deleteRider,
  createRider,
  getRiderDetails,
  getRiderDeliveries,
} from './thunks/riderThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  createLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
  singleData: null,
  singleMeta: null,
  singleIncluded: null,
  singleLogs: initialLog,

  // for Order
  riderData: null,
  riderLogs: initialLog,

  // for rider deliveries
  deliveriesData: [],
  deliveriesMeta: null,
  deliveriesIncluded: null,
  deliveriesLogs: initialLog,

  // for delete rider
  deleteLogs: initialLog,

  // For Skip Rider
  skipLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'riders',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getRiderDeliveries.fulfilled]: (state, action) => {
      state.deliveriesLogs.isSuccess = true;
      state.deliveriesLogs.isLoading = false;
      state.deliveriesData = action.payload.data;
      state.deliveriesMeta = action.payload.meta;
      state.deliveriesIncluded = action.payload.total_earnings;
    },
    [getRiderDeliveries.pending]: (state) => {
      state.deliveriesLogs.error = '';
      state.deliveriesLogs.isFailed = false;
      state.deliveriesLogs.isLoading = true;
      state.deliveriesLogs.isSuccess = false;
    },
    [getRiderDeliveries.rejected]: (state, action) => {
      state.deliveriesLogs.isFailed = true;
      state.deliveriesLogs.isLoading = false;
      state.deliveriesLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getRiders.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getRiders.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getRiders.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [getRiderDetails.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
      state.singleMeta = action.payload.meta;
      state.singleIncluded = action.payload.included;
    },
    [getRiderDetails.pending]: (state) => {
      state.singleLogs.error = '';
      state.singleLogs.isFailed = false;
      state.singleLogs.isLoading = true;
      state.singleLogs.isSuccess = false;
    },
    [getRiderDetails.rejected]: (state, action) => {
      state.singleLogs.isFailed = true;
      state.singleLogs.isLoading = false;
      state.singleLogs.error = action?.payload?.errors[0]?.detail;
    },
    [createRider.fulfilled]: (state, action) => {
      state.createLogs.isSuccess = true;
      state.createLogs.isLoading = false;
    },
    [createRider.pending]: (state) => {
      state.createLogs.error = '';
      state.createLogs.isFailed = false;
      state.createLogs.isLoading = true;
      state.createLogs.isSuccess = false;
    },
    [createRider.rejected]: (state, action) => {
      state.createLogs.isFailed = true;
      state.createLogs.isLoading = false;
      state.createLogs.error = action?.payload?.errors[0]?.detail;
    },
    [findRider.fulfilled]: (state, action) => {
      state.riderLogs.isSuccess = true;
      state.riderLogs.isLoading = false;
      state.riderData = action.payload.data;
    },
    [findRider.pending]: (state) => {
      state.riderLogs.error = '';
      state.riderLogs.isFailed = false;
      state.riderLogs.isLoading = true;
      state.riderLogs.isSuccess = false;
    },
    [findRider.rejected]: (state, action) => {
      state.riderLogs.isFailed = true;
      state.riderLogs.isLoading = false;
      state.riderLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteRider.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteRider.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteRider.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
    [skipRider.fulfilled]: (state, action) => {
      state.skipLogs.isSuccess = true;
      state.skipLogs.isLoading = false;
    },
    [skipRider.pending]: (state) => {
      state.skipLogs.error = '';
      state.skipLogs.isFailed = false;
      state.skipLogs.isLoading = true;
      state.skipLogs.isSuccess = false;
    },
    [skipRider.rejected]: (state, action) => {
      state.skipLogs.isFailed = true;
      state.skipLogs.isLoading = false;
      state.skipLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: ridersActions, reducer: ridersReducer } = slice;

export const { resetState } = ridersActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.riders || initialState;

export const getridersState = createSelector([selectDomain], (ridersState) => ridersState);

/* --------------------------------------------------------------------------------- */

export {
  getRiders,
  createRider,
  skipRider,
  findRider,
  getRiderDetails,
  getRiderDeliveries,
  deleteRider,
};
