import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import styles from './index.module.scss';

const ConfirmAction = ({ data, handleConfirm, show, type = '', onHide, ...rest }) => {
  return (
    <Modal
      size='md'
      centered
      {...rest}
      show={show}
      onHide={onHide}
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header} closeButton></Modal.Header>

      <Modal.Body className={styles?.body}>
        <p>Confirm {type}?</p>

        <div className={styles?.buttons}>
          <Button
            className={styles?.submit}
            onClick={() => {
              handleConfirm(data);
              onHide();
            }}
          >
            OK
          </Button>

          <Button className={styles?.close} onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmAction;
