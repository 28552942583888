/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Image } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import { findRider } from 'reducers/riderReducer';
import { cancelOrder, acceptOrder, getOrdersState } from 'reducers/orderReducer';
import ConfirmActionWithRemarks from '../../../components/GlobalUI/Modal/ConfirmActionWithRemarks/ConfirmActionWithRemarks';

import styles from './index.module.scss';
import logo from 'assets/images/knelogo.png';
import location from 'assets/images/LOCATION.png';
import AddressOnMap from 'components/GlobalUI/Address';
import sampleImage from 'assets/images/placeholder.png';
import orderDetails from 'assets/images/order Details.png';
import orderSummary from 'assets/images/white order summary.png';

import { ReactComponent as Close } from 'assets/svg/order_close.svg';
import { ReactComponent as Accept } from 'assets/svg/order_accept.svg';
import { ReactComponent as CloseModal } from 'assets/svg/modal_close.svg';
import { ReactComponent as OrderDetailsSVG } from 'assets/svg/order_details.svg';

const OrderDetailsModal = ({ modalData, setModalData, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectAuth);
  const { userInfo, user_type } = userState;

  const orderState = useSelector(getOrdersState);
  const { cancelLogs, acceptLogs } = orderState;

  if (!userInfo) return;

  // const channel = pusher.subscribe(`merchant-channel-${userInfo?.id}`);

  const [screen, showScreen] = useState(1);

  const [tableData, setTableData] = useState(null);
  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const onHide = () => {
    setModalData(null);
    // setTableData(null);
    setShowModal(false);
  };

  const handleOpenModal = (modalData) => {
    setShowModal(false);

    setTimeout(() => {
      setModalData(modalData);
      setShowModal(true);
    }, 500);
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  const getOrderDetails = async () => {
    const id = modalData?.id;

    try {
      axios
        .get(`api/transactions/${id}?include[0]=customer&include[1]=items&include[2]=merchant`)
        .then((response) => {
          setTableData(response?.data?.data);
        });
    } catch (error) {
      // console.log('Error', error);
    }
  };

  useEffect(() => {
    const channel = pusher.subscribe(`merchant-channel-${userInfo?.id}`);

    const handleOrderEvent = (data) => {
      // Handle the event data here
      if (data) {
        toast.info('New Order, Click to view.', {
          position: 'bottom-right',
          autoClose: false,
          closeOnClick: true,
          onClick: () => handleOpenModal(data),
        });
      }
    };

    const handleCancelEvent = (data) => {
      // Handle the event data here
      if (data) {
        if (+data['order-id'] === +modalData[`order-id`]) {
          toast.warning(`Rider ${data['rider-id']} cancelled the order. Will find new rider`);
        }
      }
    };

    const handleAcceptEvent = (data) => {
      // Handle the event data here
      if (data) {
        if (+data['order-id'] === +modalData[`order-id`]) {
          toast.success(
            `Rider ${data['rider-id']} accepted the order. check details on order page`,
          );
          onHide();
          // getOrderDetails();
        }
      }
    };

    channel.bind('create-order', handleOrderEvent);
    channel.bind('cancel-order', handleCancelEvent);
    channel.bind('accept-order', handleAcceptEvent);

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind('create-order', handleOrderEvent);
      channel.unbind('cancel-order', handleCancelEvent);
      channel.unbind('accept-order', handleAcceptEvent);
      pusher.unsubscribe(`merchant-channel-${userInfo?.id}`);
    };
  }, []);

  useEffect(() => {
    if (showModal && modalData) {
      getOrderDetails();
    }
  }, [showModal, modalData]);

  useEffect(() => {
    if (userInfo && tableData) {
      if (tableData?.distance) return;
      const merchantLong = tableData?.included?.merchant_details?.longitude;
      const merchantLat = tableData?.included?.merchant_details?.latitude;

      fetch(
        `https://ksmiguel.com/klickneat/computeDistance.php?originLat=${merchantLat}&originLng=${merchantLong}&destinationLat=${tableData?.latitude}&destinationLng=${tableData?.longitude}`,
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response.json();
        })
        .then((data) => {

          const kilometer = +data?.distanceMeters / 1000;

          setTableData((prev) => ({
            ...prev,
            distance: kilometer,
          }));
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    }
  }, [tableData, userInfo]);

  return (
    <>
      <Modal
        size='lg'
        centered
        onHide={onHide}
        show={showModal}
        className={styles?.orderDetails}
        aria-labelledby='order-created'
      >
        <Modal.Body className={styles?.body}>
          <div className={styles?.leftContainer}>
            {screen && screen === 1 && (
              <div className={styles?.orderDetails}>
                <div className={styles?.header}>
                  <OrderDetailsSVG />
                  <p>Order Details</p>
                </div>

                <div className={styles?.orderDetailsBody}>
                  {tableData?.included?.items?.map((dd, idx) => {
                    return (
                      <div key={idx} className={styles?.list}>
                        <p className={styles?.number}>#{+idx + 1}</p>

                        <Image src={dd?.imgpath} className={styles?.image} />

                        <div className={styles?.title}>
                          <h5>{dd?.name}</h5>

                          <div className={styles?.addOns}>
                            {dd?.subProduct &&
                              dd?.subProduct.length >= 1 &&
                              dd?.subProduct.map((sub, index) => {
                                return (
                                  <div key={index} className={styles?.subList}>
                                    <h6>{sub?.name}</h6>

                                    <ul>
                                      {sub?.data &&
                                        sub?.data.length >= 1 &&
                                        sub?.data?.map((subData, subIndex) => {
                                          return <li key={subIndex}>{subData}</li>;
                                        })}
                                    </ul>
                                  </div>
                                );
                              })}
                          </div>
                        </div>

                        <p className={styles?.quantity}>x{dd?.quantity}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {screen && screen === 2 && (
              <div className={styles?.addressOnMap}>
                <AddressOnMap lat={+tableData?.latitude} lng={+tableData?.longitude} />
              </div>
            )}

            <div className={styles?.footer}>
              {tableData && tableData.status === 'pending' && (
                <p className={styles?.pending}>Pending</p>
              )}
              {tableData && tableData.status === 'preparing' && (
                <p className={styles?.preparing}>Preparing</p>
              )}
              {tableData && tableData.status === 'prepared' && (
                <p className={styles?.prepared}>Delivery in Progress...</p>
              )}

              {tableData && tableData.status === 'rider-to-customer' && (
                <p className={styles?.prepared}>Delivery in Progress...</p>
              )}

              {tableData && tableData.status === 'delivered' && (
                <p className={styles?.prepared}>Successfully Delivered</p>
              )}
            </div>

            <div className={styles?.selection}>
              <div className={styles?.data}>
                <div
                  onClick={() => showScreen(1)}
                  className={`${styles?.image} ${screen === 1 ? styles?.active : ''}`}
                >
                  <Image src={orderDetails} />
                </div>

                <p>ORDER DETAILS</p>
              </div>

              <div className={styles?.data}>
                <div
                  onClick={() => showScreen(2)}
                  className={`${styles?.image} ${screen === 2 ? styles?.active : ''}`}
                >
                  <Image src={location} />
                </div>

                <p>LOCATION</p>
              </div>
            </div>
          </div>

          <div className={styles?.rightContainer}>
            <CloseModal onClick={onHide} className={styles?.closemodal} />

            <div className={styles?.orderHeader}>
              <Image src={logo} />

              <p>INCOMING ORDER</p>
            </div>

            <div className={styles?.customer}>
              <Image src={sampleImage} />
              <h3>{`${tableData?.included?.customer_details?.first_name} ${tableData?.included?.customer_details?.last_name}`}</h3>
              <h4>{tableData?.included?.customer_details?.mobile_number}</h4>
              <p>{tableData?.included?.customer_details?.address}</p>
              <p className={styles?.distance}>{tableData?.distance}km away</p>
            </div>

            <hr className={styles?.line} />

            <div className={styles?.orderSummary}>
              <div className={styles?.summaryHeader}>
                <Image src={orderSummary} />
                <p>
                  Order Summary <span>{tableData?.created?.string}</span>
                </p>
              </div>

              <div className={styles?.summaryBody}>
                <div className={styles?.row}>
                  <p>Order</p>
                  <p>PHP {tableData?.subtotal}</p>
                </div>

                <div className={styles?.row}>
                  <p>Delivery</p>
                  <p>PHP {tableData?.delivery_fee}</p>
                </div>

                <div className={styles?.row}>
                  <p>Promo Discount</p>
                  <p>PHP -{tableData?.discount}</p>
                </div>

                <hr className={styles?.summaryTotalLine} />

                <div className={styles?.total}>
                  <p>Total</p>
                  <p>PHP {tableData?.totalAmount}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ConfirmActionWithRemarks
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </>
  );
};

const pusher = new Pusher('490ce039becb2a8b6a9d', {
  cluster: 'ap1',
});

export default OrderDetailsModal;
