/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import FormUpload from 'components/GlobalUI/FormUpload';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { uploadMasterFile, getMerchantsState } from 'reducers/merchantReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

// Main component
const UploadMaster = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const merchantState = useSelector(getMerchantsState);
  const { masterLogs } = merchantState;

  const [formData, setFormData] = useState({
    file: [],
  });

  const handleCancel = () => {
    navigate('/merchants');
  };

  const handleUpload = async () => {
    const apiData = new FormData();
    apiData.append('file', formData?.file[0]);

    try {
      const response = await dispatch(uploadMasterFile(apiData));

      if (response) {
        toast.info('Successfully uploaded master file');
      }

      setFormData({
        file: [],
      });
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <PrivateLayout pageTitle='Upload Master File'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>UPLOAD MERCHANT MASTER FILE</h4>
              <p>
                <span>MERCHANT</span> / UPLOAD MASTER FILE
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.AddMerchant}>
              <p className={styles?.productHeader}>
                UPLOAD PRODUCT MASTER FILE <span>Upload Master File here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Master File</span>

                  <FormUpload
                    name='file'
                    accept='.xlsx, .xls, .csv'
                    setState={setFormData}
                    stateValue={formData?.file}
                    haveClass={styles?.formUpload}
                  />
                </div>

                <div className={styles?.column}></div>

                <div className={styles?.column}></div>
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleUpload}
                  className={styles?.add}
                  disabled={masterLogs?.isLoading}
                >
                  <PlusSVG />
                  UPLOAD
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default UploadMaster;
