/* eslint-disable no-unreachable */
/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Row,
  Form,
  Table,
  Button,
  Popover,
  Dropdown,
  Container,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import TablePagination from 'components/GlobalUI/TablePagination';
import { getRiders, getridersState, deleteRider } from 'reducers/riderReducer';
import ConfirmAction from 'components/GlobalUI/Modal/ConfirmAction/ConfirmAction';

// Assets
import styles from './index.module.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

// Main component
const Riders = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef();

  const riderState = useSelector(getridersState);
  const { meta, logs } = riderState;

  const [tableData, setTableData] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    status: '',
  });

  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const handleAddRider = () => {
    navigate(`/riders/add`);
  };

  const handleView = (data) => {
    if (!data?.id) return;

    navigate(`/riders/view/${data?.id}`);
  };

  const handleRiders = () => {
    const unfinalParams = {
      page: filter?.page,
      search: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const tryCatch = async (params) => {
    setTableData([]);

    try {
      const response = await dispatch(getRiders(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          status: dd?.account_status,
          plateNo: dd?.vehicle?.plate_no,
          licenseNo: dd?.drivers_license_no,
          riderName: `${dd?.firstname} ${dd?.lastname}`,
          vehicleModel: `${dd?.vehicle?.brand} ${dd?.vehicle?.model}`,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleFilter = (filterBy) => {
    switch (filterBy) {
      case 'Approved':
        setFilter((prev) => ({
          ...prev,
          status: 'Approved',
        }));

        const approvedParams = {
          status: 'Approved',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalApprovedparams = objectCleaner(approvedParams);

        tryCatch(finalApprovedparams);
        break;
      case 'Disapproved':
        setFilter((prev) => ({
          ...prev,
          status: 'Disapproved',
        }));

        const disapprovedParams = {
          page: filter?.page,
          status: 'Disapproved',
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalDisapprovedParams = objectCleaner(disapprovedParams);

        tryCatch(finalDisapprovedParams);
        break;
      default:
        break;
    }
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      type: filter?.type,
      search: filter?.search,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        type: filter?.type,
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  const handleDeletion = async (data) => {
    try {
      const response = await dispatch(deleteRider(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully deleted rider');
        handleRiders();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    handleRiders();
  }, []);

  return (
    <PrivateLayout pageTitle='Riders'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>RIDERS</h4>
              <p>
                <span>RIDERS</span> / RIDERS LIST
              </p>
            </div>

            <div className={styles?.actions}>
              <Button onClick={handleAddRider}>
                <p>Add New Rider</p> <PlusSVG />
              </Button>

              <Dropdown>
                <Dropdown.Toggle variant='success' className={styles?.filter}>
                  {filter?.status === '' ? 'Select Status' : filter?.status}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {filter?.status !== 'Approved' && (
                    <Dropdown.Item onClick={() => handleFilter('Approved')}>Approved</Dropdown.Item>
                  )}

                  {filter?.status !== 'Disapproved' && (
                    <Dropdown.Item onClick={() => handleFilter('Disapproved')}>
                      Disapproved
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                RIDERS LIST<span>List of Riders</span>
              </p>

              <Table responsive striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Rider Name</th>
                    <th>Vehicle Model</th>
                    <th>Plate No.</th>
                    <th>License No.</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((dd) => (
                      <tr key={dd?.id}>
                        <td>{dd?.riderName}</td>
                        <td>{dd?.vehicleModel}</td>
                        <td>{dd?.plateNo}</td>
                        <td>{dd?.licenseNo}</td>
                        <td>
                          <span className={styles[dd?.status]}>{dd?.status}</span>
                        </td>
                        <td>
                          <OverlayTrigger
                            trigger='click'
                            placement='left'
                            rootClose
                            overlay={
                              <Popover id='popover-table-action'>
                                <Popover.Body className='d-flex justify-content-between p-0'>
                                  <div className='button-actions'>
                                    <Button onClick={() => handleView(dd)} className='edit'>
                                      View Rider
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        setShowConfirm({
                                          data: dd,
                                          show: true,
                                          submit: handleDeletion,
                                          type: 'deletion of rider account?',
                                        })
                                      }
                                      className='edit'
                                    >
                                      Remove Rider
                                    </Button>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant='secondary'
                              className='popoverButton bg-transparent p-0 border-0'
                            >
                              <ActionSVG className={styles?.action} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
              </Table>

              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmAction
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </PrivateLayout>
  );
};

export default Riders;
