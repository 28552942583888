/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Container, Row, Col, FormControl, Button } from 'react-bootstrap';

// Components
import { updateProfile } from 'reducers/userReducer';
import ConditionalRender from 'components/ConditionalRender';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';

// Assets
import styles from './index.module.scss';
import blankProfile from 'assets/images/blank-profile.png';
import { ReactComponent as UpdateProfileSVG } from 'assets/svg/update-profile-icon.svg';

// Main component
const UserAccount = () => {
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  const [formData, setFormData] = useState({});

  const isReadOnly = () => {
    if (isEdit) {
      return false;
    }

    return true;
  };

  const handleSave = () => {
    // eslint-disable-next-line prefer-const
    let submitData = new FormData();

    submitData.append('age', formData?.age || '');
    submitData.append('gender', formData?.gender || '');
    submitData.append('last_name', formData?.last_name || '');
    submitData.append('first_name', formData?.first_name || '');
    submitData.append('middle_name', formData?.middle_name || '');
    submitData.append('contact_number', formData?.contact_number || '');
    submitData.append('profile_picture', formData?.profile_picture || '');

    dispatch(
      updateProfile({
        id: formData?.id,
        payload: submitData,
      }),
    );
  };

  const handleChange = (e) => {
    const { value, name, type } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleImageChange = () => {
    if (formData?.profile_picture) {
      return formData?.profile_picture;
    }

    return blankProfile;
  };

  const handleImageUpload = (e) => {
    const { value, name, files } = e.target;

    const [file] = e.target.files;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: files[0],
        imagePreview: URL.createObjectURL(file),
      };
    });
  };

  const handleEnableUpdate = () => {
    setIsEdit(true);
  };

  const handleDisableUpdate = () => {
    setIsEdit(false);
  };

  return (
    <PrivateLayout pageTitle='Profile Account'>
      <Container>
        <div className={styles['profile']}>
          <Row className='mb-4'>
            <Col>
              <div className={`${styles['user-profile']} shadow`}>
                <div className={styles['image-container']}>
                  <img src={formData?.imagePreview || handleImageChange()} alt='profile picture' />

                  <div className={styles['profile-update']}>
                    <label>
                      <input
                        type='file'
                        name='profile_picture'
                        style={{ display: 'none' }}
                        onChange={handleImageUpload}
                        accept='image/png, image/jpeg'
                      />
                      <UpdateProfileSVG />
                    </label>
                  </div>
                </div>
                <div className={styles['profile-container']}>
                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>User Role :</Form.Label>
                    <FormControl
                      name='role'
                      readOnly={isReadOnly()}
                      value={formData?.role}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>First Name :</Form.Label>
                    <FormControl
                      name='first_name'
                      readOnly={isReadOnly()}
                      value={formData?.first_name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>Middle Name :</Form.Label>
                    <FormControl
                      name='middle_name'
                      readOnly={isReadOnly()}
                      value={formData?.middle_name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>Last Name :</Form.Label>
                    <FormControl
                      name='last_name'
                      readOnly={isReadOnly()}
                      value={formData?.last_name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>Age :</Form.Label>
                    <FormControl
                      type='number'
                      name='age'
                      readOnly={isReadOnly()}
                      value={formData?.age}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>Gender :</Form.Label>
                    <Form.Select
                      type='number'
                      name='gender'
                      readOnly={isReadOnly()}
                      onChange={handleChange}
                      value={formData?.gender}
                    >
                      <option value='' hidden>
                        Select Gender
                      </option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className={styles['inline-group']}>
                    <Form.Label>Contact Number :</Form.Label>
                    <FormControl
                      type='number'
                      name='contact_number'
                      readOnly={isReadOnly()}
                      value={formData?.contact_number}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <div className={styles['header']}>
                    <div className={styles['buttons-container']}>
                      <ConditionalRender
                        condition={isEdit === false}
                        renderIf={<Button onClick={handleEnableUpdate}>Update</Button>}
                      />

                      <ConditionalRender
                        condition={isEdit === true}
                        renderIf={
                          <>
                            <Button onClick={handleSave}>Save</Button>

                            <Button
                              className={styles['cancel-button']}
                              onClick={handleDisableUpdate}
                            >
                              Cancel
                            </Button>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </PrivateLayout>
  );
};

export default UserAccount;
