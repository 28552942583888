/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Table, Image, Popover } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import FormSelection from 'components/GlobalUI/FormSelection';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { toggleStatusMerchant } from 'reducers/merchantReducer';
import TablePagination from 'components/GlobalUI/TablePagination';
import SamplePagination from 'components/GlobalUI/SamplePagination';
import ConfirmAction from 'components/GlobalUI/Modal/ConfirmAction/ConfirmAction';
import { getMerchant, getMerchantsState, getMerchantProducts } from 'reducers/merchantReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as PlusSVG } from 'assets/svg/white store.svg';

// Main component
const ViewMerchant = () => {
  const dispatch = useDispatch();

  const merchantState = useSelector(getMerchantsState);
  const { productLogs, productMeta, productData } = merchantState;

  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    title: '',
    filter: '',
    subtitle: '',
    products: [],
    data: {
      name: '',
      avatar: [],
      address: '',
      category: null,
      storeHours: '',
      emailAddress: '',
      contactNumber: '',
    },
  });

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    status: '',
  });

  const [categories, setCategories] = useState([]);
  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const handleCancel = () => {
    navigate('/merchants');
  };

  const handleEditMerchantProduct = (id) => {
    navigate(`/merchants/product/${id}`);
  };

  const getMmerchantInfo = async () => {
    try {
      const response = await dispatch(getMerchant(id));

      // const products = response?.payload?.data?.included?.products?.map((dd) => {
      //   return {
      //     ...dd,
      //     price: dd?.price,
      //     status: dd?.status,
      //     productName: dd?.name,
      //     inventory: dd?.availability,
      //   };
      // });

      const haveStart = response?.payload?.data?.included?.store_hours?.time_start !== null;
      const haveEnd = response?.payload?.data?.included?.store_hours?.time_end !== null;

      const storeHours = `${
        haveStart ? `${response?.payload?.data?.included?.store_hours?.time_start}` : ''
      } ${haveEnd ? `${response?.payload?.data?.included?.store_hours?.time_end}` : ''}`;

      const newData = {
        ...response?.payload?.data,
        // products: products,
        storeHours: storeHours,
        name: response?.payload?.data?.name,
        address: response?.payload?.data?.address,
        emailAddress: response?.payload?.data?.email,
        avatar: [response?.payload?.data?.files?.avatar],
        contactNumber: response?.payload?.data?.contact_no,
        category: response?.payload?.data?.included?.category?.id,
      };

      setFormData((prev) => ({
        ...prev,
        data: newData,
      }));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleDeactivation = async (data) => {
    const apiData = new FormData();
    apiData.append('status', 'Inactive');
    apiData.append('merchant_id', id);

    try {
      const response = await dispatch(toggleStatusMerchant(apiData));

      if (response) {
        toast.info('Successfully deactivated merchant account');
        getMmerchantInfo();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleActivation = async (data) => {
    const apiData = new FormData();
    apiData.append('status', 'Active');
    apiData.append('merchant_id', id);

    try {
      const response = await dispatch(toggleStatusMerchant(apiData));

      if (response) {
        toast.info('Successfully deactivated merchant account');
        getMmerchantInfo();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  const getCategoryies = async () => {
    try {
      axios.get('api/merchant-categories').then((response) => {
        setCategories(response?.data?.data);
      });
    } catch (error) {
      // console.log('Error', error);
    }
  };

  const tryGetProducts = async (params) => {
    try {
      const response = await dispatch(getMerchantProducts({ id: id, params: params }));

      const product = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          price: dd?.price,
          productName: dd?.name,
          inventory: dd?.inventory,
          status: dd?.availability ? 'Available' : 'Unavailable',
        };
      });

      setFormData((prev) => ({
        ...prev,
        products: product,
      }));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const getProducts = () => {
    const unfinalParams = {
      page: filter?.page,
      search: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryGetProducts(params);
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      search: filter?.search,
    };

    const params = objectCleaner(newObj);

    tryGetProducts(params);
  };

  useEffect(() => {
    if (id) {
      getProducts();
      getCategoryies();
      getMmerchantInfo();
    }
  }, [id]);

  return (
    <PrivateLayout pageTitle='View Active Merchant'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>VIEW ACTIVE MERCHANT</h4>
              <p>
                <span>ACTIVE MERCHANT LIST</span> / VIEW ACTIVE MERCHANT
              </p>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='p-0'>
            <div className={styles?.AddMerchant}>
              <p className={styles?.productHeader}>
                VIEW ACTIVE MERCHANT <span>View Active Merchant Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Avatar</span>
                  <Image name='avatar' src={formData?.data?.avatar} />
                  {/* <ImageUpload
                    name='avatar'
                    setState={setFormData}
                    stateValue={formData?.data?.avatar}
                  /> */}
                </div>

                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Name'
                    setState={setFormData}
                    placeholder={'Enter Name'}
                    haveClass={styles?.fromGroup}
                    stateValue={formData?.data?.name}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Address'}
                    stateValue={formData?.data?.address}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Contact Number'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact Number'}
                    stateValue={formData?.data?.contactNumber}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    readOnly
                    type='text'
                    name='Email Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Email Address'}
                    stateValue={formData?.data?.emailAddress}
                  />

                  <FormText
                    readOnly
                    type='text'
                    name='Store Hours'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Store Hours'}
                    stateValue={formData?.data?.storeHours}
                  />

                  <FormSelection
                    readOnly
                    type='text'
                    name='Category'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Select Category'}
                    stateValue={formData?.data?.category ? formData?.data?.category : ''}
                    options={categories.map((dd) => ({
                      ...dd,
                      value: dd?.id,
                      label: dd?.name,
                    }))}
                  />
                </div>
              </div>

              <div className={styles?.table}>
                <p className={styles?.tableHeader}>
                  PRODUCT LIST <span>List of Products</span>
                </p>
                <Table striped bordered hover className={styles?.table}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Inventory</th>
                      <th>Price</th>
                      <th>Status</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {formData?.products &&
                      formData?.products.map((dd) => (
                        <tr key={dd?.id}>
                          <td>{dd?.productName}</td>
                          <td>{dd?.inventory}</td>
                          <td>{dd?.price}</td>
                          <td>
                            <span className={styles[`${dd?.status}`]}>{dd?.status}</span>
                          </td>
                          {/* <td>
                            <OverlayTrigger
                              trigger='click'
                              placement='left'
                              rootClose
                              overlay={
                                <Popover id='popover-table-action'>
                                  <Popover.Body className='d-flex justify-content-between p-0'>
                                    <div className='button-actions'>
                                      <Button>Set as Unavailable</Button>
                                      <Button onClick={() => handleEditMerchantProduct(dd?.id)}>
                                        Edit Product
                                      </Button>
                                      <Button>Remove Product</Button>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button
                                variant='secondary'
                                className='popoverButton bg-transparent p-0 border-0'
                              >
                                <ActionSVG className={styles?.action} />
                              </Button>
                            </OverlayTrigger>
                          </td> */}
                        </tr>
                      ))}
                    <tr></tr>
                  </tbody>
                </Table>
                <TablePagination
                  meta={productMeta}
                  setParams={setFilter}
                  loading={productLogs?.isLoading}
                  handlePaginationClick={handlePaginationClick}
                />
              </div>

              <div className={styles?.actions}>
                {formData?.data?.status === 'Active' && (
                  <Button
                    onClick={() =>
                      setShowConfirm({
                        data: formData?.id,
                        show: true,
                        submit: handleDeactivation,
                        type: 'deactivation of merchant account?',
                      })
                    }
                    className={styles?.add}
                  >
                    <PlusSVG />
                    DEACTIVE MERCHANT
                  </Button>
                )}

                {formData?.data?.status === 'Inactive' && (
                  <Button
                    onClick={() =>
                      setShowConfirm({
                        data: formData?.id,
                        show: true,
                        submit: handleActivation,
                        type: 'activation of merchant account?',
                      })
                    }
                    className={styles?.add}
                  >
                    <PlusSVG />
                    ACTIVATE MERCHANT
                  </Button>
                )}

                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmAction
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </PrivateLayout>
  );
};

export default ViewMerchant;
