/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  Popover,
  Dropdown,
  Container,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import ConfirmAction from './Modals/ConfirmAction';
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import TablePagination from 'components/GlobalUI/TablePagination';
import {
  getMerchants,
  getMerchantsState,
  toggleStatusMerchant,
  toggleFeaturedMerchant,
} from 'reducers/merchantReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

// Main component
const Merchants = () => {
  const dispatch = useDispatch();

  const timerRef = useRef(null);

  const navigate = useNavigate();

  const merchantState = useSelector(getMerchantsState);
  const { meta, logs } = merchantState;

  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const [tableData, setTableData] = useState({
    data: [],
    filter: 'Active Merchants',
    title: 'ACTIVE MERCHANT LIST',
    subtitle: 'List of Active Merchants',
  });

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
    status: 'Active',
  });

  const handleAddMerchant = () => {
    navigate('/merchants/add-merchant');
  };

  const handleUpload = () => {
    navigate('/merchants/upload-file');
  };

  const handleView = (id) => {
    navigate(`/merchants/active/${id}`);
  };

  const handleViewApprove = (id) => {
    navigate(`/merchants/pending/${id}`);
  };

  const handleFilter = (filterBy) => {
    switch (filterBy) {
      case 'Active Merchants':
        setFilter((prev) => ({
          ...prev,
          status: 'Active',
        }));

        const activeParams = {
          status: 'Active',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalActiveparams = objectCleaner(activeParams);

        const activeData = {
          filter: 'Active Merchants',
          title: 'ACTIVE MERCHANT LIST',
          subtitle: 'List of Active Merchants',
        };

        tryCatch(finalActiveparams, activeData);
        break;
      case 'Pending Merchants':
        setFilter((prev) => ({
          ...prev,
          status: 'Pending',
        }));

        const pendingParams = {
          status: 'Pending',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalPendingParams = objectCleaner(pendingParams);

        const pendingData = {
          filter: 'Pending Merchants',
          title: 'FOR APPROVAL MERCHANT LIST',
          subtitle: 'List of Pending Merchants',
        };

        tryCatch(finalPendingParams, pendingData);
        break;
      case 'Featured Merchants':
        setFilter((prev) => ({
          ...prev,
          status: 'Featured',
        }));

        const featuredParams = {
          status: 'Featured',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalFeaturedParams = objectCleaner(featuredParams);

        const featuredData = {
          filter: 'Featured Merchants',
          title: 'FEATURED MERCHANT LIST',
          subtitle: 'List of Featured Merchants',
        };

        tryCatch(finalFeaturedParams, featuredData);
        break;
      case 'Inactive Merchants':
        setFilter((prev) => ({
          ...prev,
          status: 'Inactive',
        }));

        const inactiveParams = {
          status: 'Inactive',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
        };

        const finalInactiveParams = objectCleaner(inactiveParams);

        const inactiveData = {
          filter: 'Inactive Merchants',
          title: 'INACTIVE MERCHANT LIST',
          subtitle: 'List of Inactive Merchants',
        };

        tryCatch(finalInactiveParams, inactiveData);
        break;
      default:
        break;
    }
  };

  const handleMerchants = () => {
    const unfinalParams = {
      page: filter?.page,
      status: filter?.status,
      search: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const tryCatch = async (params, data = null) => {
    try {
      const response = await dispatch(getMerchants(params));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          email: dd?.email,
          status: dd?.status,
          address: dd?.address,
          merchantName: dd?.name,
          contactNo: dd?.contact_no,
        };
      });

      setTableData((prev) => ({
        ...prev,
        ...data,
        data: newData,
      }));
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      status: filter?.status,
      search: filter?.search,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        status: filter?.status,
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleFeatured = async (data) => {
    const apiData = new FormData();
    apiData.append('merchant_id', data?.id);
    apiData.append('is_featured', '1');

    try {
      const response = await dispatch(toggleFeaturedMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully featured merchant account');
        handleMerchants();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUnfeatured = async (data) => {
    const apiData = new FormData();
    apiData.append('merchant_id', data?.id);
    apiData.append('is_featured', '0');

    try {
      const response = await dispatch(toggleFeaturedMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully unfeatured merchant account');
        handleMerchants();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleDeactivation = async (data) => {
    const apiData = new FormData();
    apiData.append('status', 'Inactive');
    apiData.append('merchant_id', data?.id);

    try {
      const response = await dispatch(toggleStatusMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully deactivated merchant account');
        handleMerchants();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleReactivation = async (data) => {
    const apiData = new FormData();
    apiData.append('status', 'Active');
    apiData.append('merchant_id', data?.id);

    try {
      const response = await dispatch(toggleStatusMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully reactivated merchant account');
        handleMerchants();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleApprove = async (data) => {
    const apiData = new FormData();
    apiData.append('status', 'Active');
    apiData.append('merchant_id', data?.id);

    try {
      const response = await dispatch(toggleStatusMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        toast.info('Successfully approved merchant account');
        handleMerchants();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  useEffect(() => {
    handleMerchants();
  }, []);

  return (
    <PrivateLayout pageTitle='Merchants'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>MERCHANTS</h4>
              <p>
                <span>MERCHANTS</span> / MERCHANT LIST
              </p>
            </div>

            <div className={styles?.actions}>
              <Button onClick={handleUpload}>
                <p>Upload Master File</p> <PlusSVG />
              </Button>

              <Button onClick={handleAddMerchant}>
                <p>Add New Merchant</p> <PlusSVG />
              </Button>

              <Dropdown>
                <Dropdown.Toggle variant='success' className={styles?.filter}>
                  {tableData?.filter}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {tableData?.filter !== 'Active Merchants' && (
                    <Dropdown.Item onClick={() => handleFilter('Active Merchants')}>
                      Active Merchants
                    </Dropdown.Item>
                  )}

                  {tableData?.filter !== 'Pending Merchants' && (
                    <Dropdown.Item onClick={() => handleFilter('Pending Merchants')}>
                      Pending Merchants
                    </Dropdown.Item>
                  )}

                  {tableData?.filter !== 'Featured Merchants' && (
                    <Dropdown.Item onClick={() => handleFilter('Featured Merchants')}>
                      Featured Merchants
                    </Dropdown.Item>
                  )}

                  {tableData?.filter !== 'Inactive Merchants' && (
                    <Dropdown.Item onClick={() => handleFilter('Inactive Merchants')}>
                      Inactive Merchants
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                {tableData?.title}
                <span> {tableData?.subtitle}</span>
              </p>

              <Table responsive striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Merchant Name</th>
                    <th>Address</th>
                    <th>Contact No.</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.data &&
                    tableData?.data.map((dd) => (
                      <tr key={dd?.id}>
                        <td>{dd?.merchantName}</td>
                        <td>{dd?.address}</td>
                        <td>{dd?.contactNo}</td>
                        <td>{dd?.email}</td>
                        <td>
                          <span
                            className={
                              styles[
                                `${
                                  dd?.status?.includes('Active') || dd?.status?.includes('Featured')
                                    ? 'Active'
                                    : 'Pending'
                                }`
                              ]
                            }
                          >
                            {dd?.status}
                          </span>
                        </td>
                        <td>
                          <OverlayTrigger
                            trigger='click'
                            placement='left'
                            rootClose
                            overlay={
                              <Popover id='popover-table-action'>
                                <Popover.Body className='d-flex justify-content-between p-0'>
                                  <div className='button-actions'>
                                    {tableData?.title === 'FOR APPROVAL MERCHANT LIST' && (
                                      <>
                                        <Button
                                          onClick={() => handleViewApprove(dd?.id)}
                                          className='delete'
                                        >
                                          View Merchant
                                        </Button>
                                        <Button
                                          className='delete'
                                          onClick={() =>
                                            setShowConfirm({
                                              data: dd,
                                              show: true,
                                              submit: handleApprove,
                                              type: 'approve merchant account',
                                            })
                                          }
                                        >
                                          Approve Merchant
                                        </Button>
                                      </>
                                    )}

                                    {(tableData?.title === 'ACTIVE MERCHANT LIST' ||
                                      tableData?.title === 'FEATURED MERCHANT LIST') && (
                                      <>
                                        <Button
                                          onClick={() => handleView(dd?.id)}
                                          className='delete'
                                        >
                                          View Merchant
                                        </Button>

                                        {dd?.is_featured === false && (
                                          <Button
                                            onClick={() =>
                                              setShowConfirm({
                                                data: dd,
                                                show: true,
                                                submit: handleFeatured,
                                                type: 'set as featured action',
                                              })
                                            }
                                            className='delete'
                                          >
                                            Set as Featured
                                          </Button>
                                        )}

                                        {dd?.is_featured === true && (
                                          <Button
                                            onClick={() =>
                                              setShowConfirm({
                                                data: dd,
                                                show: true,
                                                submit: handleUnfeatured,
                                                type: 'set as unfeatured action',
                                              })
                                            }
                                            className='delete'
                                          >
                                            Set as UnFeatured
                                          </Button>
                                        )}

                                        <Button
                                          onClick={() =>
                                            setShowConfirm({
                                              data: dd,
                                              show: true,
                                              submit: handleDeactivation,
                                              type: 'deactivation of merchant account?',
                                            })
                                          }
                                          className='delete'
                                        >
                                          Deactivate Merchant
                                        </Button>
                                      </>
                                    )}

                                    {tableData?.title === 'INACTIVE MERCHANT LIST' && (
                                      <>
                                        <Button
                                          onClick={() => handleView(dd?.id)}
                                          className='delete'
                                        >
                                          View Merchant
                                        </Button>

                                        <Button
                                          onClick={() =>
                                            setShowConfirm({
                                              data: dd,
                                              show: true,
                                              submit: handleReactivation,
                                              type: 'reactivation of merchant account?',
                                            })
                                          }
                                          className='delete'
                                        >
                                          Reactivate Merchant
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant='secondary'
                              className='popoverButton bg-transparent p-0 border-0'
                            >
                              <ActionSVG className={styles?.action} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
              </Table>

              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmAction
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </PrivateLayout>
  );
};

export default Merchants;
