/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Table, OverlayTrigger, Popover } from 'react-bootstrap';

// Components
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import SamplePagination from 'components/GlobalUI/SamplePagination';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';

const sampleData = [
  {
    id: 1,
    inventory: '1000',
    price: 'P 150.00',
    status: 'Available',
    productName: 'Product 1',
  },
  {
    id: 2,
    inventory: '1000',
    price: 'P 150.00',
    status: 'Available',
    productName: 'Product 2',
  },
  {
    id: 3,
    inventory: '1000',
    price: 'P 150.00',
    status: 'Available',
    productName: 'Product3',
  },
  {
    id: 4,
    inventory: '1000',
    price: 'P 150.00',
    status: 'Available',
    productName: 'Product 4',
  },
  {
    id: 5,
    inventory: '1000',
    price: 'P 150.00',
    status: 'Available',
    productName: 'Product 5',
  },
];

// Main component
const ViewPendingMerchant = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    avatar: '',
    address: '',
    storeHours: '',
    emailAddress: '',
    contactNumber: '',
  });

  const handleCancel = () => {
    navigate('/merchants');
  };

  const handleEditMerchantProduct = (id) => {
    navigate(`/merchants/product/${id}`);
  };

  return (
    <PrivateLayout pageTitle='View Pending Merchant'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>VIEW PENDING MERCHANT</h4>
              <p>
                <span>PENDING MERCHANT LIST</span> / VIEW PENDING MERCHANT
              </p>
            </div>
          </Col>
        </Row>

        <Row className='mb-4'>
          <Col className='p-0'>
            <div className={styles?.AddMerchant}>
              <p className={styles?.productHeader}>
                VIEW PENDING MERCHANT <span>View Pending Merchant Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Avatar</span>
                  <ImageUpload name='avatar' setState={setFormData} stateValue={formData?.avatar} />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Name'}
                    stateValue={formData?.name}
                  />

                  <FormText
                    type='text'
                    name='Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Address'}
                    stateValue={formData?.address}
                  />

                  <FormText
                    type='text'
                    name='Contact Number'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact Number'}
                    stateValue={formData?.contactNumber}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Email Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Email Address'}
                    stateValue={formData?.emailAddress}
                  />

                  <FormText
                    type='text'
                    name='Store Hours'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Store Hours'}
                    stateValue={formData?.storeHours}
                  />
                </div>
              </div>

              <div className={styles?.table}>
                <p className={styles?.tableHeader}>
                  PRODUCT LIST <span>List of Products</span>
                </p>

                <Table striped bordered hover className={styles?.table}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Inventory</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sampleData &&
                      sampleData.map((dd) => (
                        <tr key={dd?.id}>
                          <td>{dd?.productName}</td>
                          <td>{dd?.inventory}</td>
                          <td>{dd?.price}</td>
                          <td>
                            <span className={styles[`${dd?.status}`]}>{dd?.status}</span>
                          </td>
                          <td>
                            <OverlayTrigger
                              trigger='click'
                              placement='left'
                              rootClose
                              overlay={
                                <Popover id='popover-table-action'>
                                  <Popover.Body className='d-flex justify-content-between p-0'>
                                    <div className='button-actions'>
                                      <Button>Set as Unavailable</Button>
                                      <Button onClick={() => handleEditMerchantProduct(dd?.id)}>
                                        Edit Product
                                      </Button>
                                      <Button>Remove Product</Button>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button
                                variant='secondary'
                                className='popoverButton bg-transparent p-0 border-0'
                              >
                                <ActionSVG className={styles?.action} />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    <tr></tr>
                  </tbody>
                </Table>

                <SamplePagination />
              </div>

              <div className={styles?.actions}>
                <Button className={styles?.add}>
                  <PlusSVG />
                  APPROVE MERCHANT
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default ViewPendingMerchant;
