/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUI/ImageUI';
import FormText from 'components/GlobalUI/FormText';
import ButtonSubmit from 'components/GlobalUI/ButtonSubmit';
import { login, selectAuth, merchantLogin, setIsAuthenticated } from 'reducers/authReducer';

// Assets
import styles from './index.module.scss';
import Logo from 'assets/images/logo.png';
import LoginImage from 'assets/images/login Image.png';
import placeholderLogo from 'assets/images/placeholder.png';

const initialState = {
  password: '',
  emailAddress: '',
  rememberMe: false,
};

// Main Component
const Login = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector(selectAuth);
  const { loginLogs, userInfo } = authState;

  const [formData, setFormData] = useState(initialState);

  const handleSubmit = (e) => {
    if (!type) return;

    e.preventDefault();

    const params = {
      login_type: type,
      password: formData?.password,
      email: formData?.emailAddress,
    };

    dispatch(login(params));

    // if (type === 'admin') {
    //   const params = {
    //     email: formData?.emailAddress,
    //     password: formData?.password,
    //   };
    //   dispatch(login(params));
    // }

    // if (type === 'merchant') {
    //   const params = {
    //     email: formData?.emailAddress,
    //     password: formData?.password,
    //   };
    //   dispatch(merchantLogin(params));
    // }
  };

  const emailProps = {
    haveClass: '',
    noLabel: true,
    type: 'email',
    required: true,
    name: 'Email Address',
    setState: setFormData,
    placeholder: 'Enter your email',
    stateValue: formData?.emailAddress,
  };

  const passwordProps = {
    noLabel: true,
    required: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    stateValue: formData?.password,
    placeholder: 'Enter your password',
  };

  const submitButtonProps = {
    type: 'submit',
    value: 'Sign In',
    variant: 'primary',
    className: styles?.submitButton,
    isDisabled: loginLogs?.isLoading,
  };

  renderSubmitCallback(loginLogs, userInfo, dispatch, navigate, type);

  return (
    <Container fluid className={styles?.container}>
      <div className={styles?.loginContainer}>
        <Form className={styles?.loginForm} onSubmit={handleSubmit}>
          <div className={styles?.loginHeader}>
            <ImageUI src={Logo} fallbackSrc={placeholderLogo} />
            <h6>Welcome Back</h6>
            <p>Hello there, sign in to continue</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />

            <FormText {...passwordProps} />
          </div>

          <div className={`${styles?.formContainer} ${styles?.formHorizontal}`}>
            <Link className={styles?.forgotPassword} to='/account-recovery'>
              Forgot Password?
            </Link>
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            {/* <div className={styles?.signup}>
              <p>Don&apos;t have an account?</p>

              <Link className={styles?.link} to='/sign-up'>
                Sign Up
              </Link>
            </div> */}
          </div>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>
      </div>
    </Container>
  );
};

const renderSubmitCallback = (loginLogs, userInfo, dispatch, navigate, type) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = loginLogs;

    if (isSuccess) {
      // eslint-disable-next-line no-unreachable
      dispatch(setIsAuthenticated());

      if (type === 'admin') {
        navigate('/');
      } else {
        navigate('/products');
      }
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [loginLogs]);
};

export default Login;
