/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

import FormText from 'components/GlobalUI/FormText';

import styles from './index.module.scss';
import { ReactComponent as MinusSVG } from 'assets/svg/arrow-left.svg';

const Profile = ({ apiData }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    riderPhoto: [],
    driverLicense: [],
    motorcyclePhoto: [],
    oRCr: [],
    firstName: '',
    contactNo: '',
    vehicleBrand: '',
    vehiclePlateNo: '',
    lastName: '',
    birthDate: '',
    vehicleModel: '',
    oRNo: '',
    emailAddress: '',
    driversLicenseNo: '',
    vehicleColor: '',
    cRNo: '',
  });

  const handleCancel = () => {
    navigate('/riders');
  };

  useEffect(() => {

    setFormData((prev) => ({
      ...prev,
      oRCr: [apiData?.files?.img_or],
      riderPhoto: [apiData?.files?.avatar],
      driverLicense: [apiData?.files?.img_drivers_license],
      motorcyclePhoto: [apiData?.files?.img_motorcycle],
      lastName: apiData?.lastname,
      emailAddress: apiData?.email,
      oRNo: apiData?.vehicle?.or_no,
      cRNo: apiData?.vehicle?.cr_no,
      firstName: apiData?.firstname,
      birthDate: apiData?.birthdate,
      contactNo: apiData?.contact_no,
      vehicleBrand: apiData?.vehicle?.brand,
      vehicleModel: apiData?.vehicle?.model,
      vehicleColor: apiData?.vehicle?.color,
      vehiclePlateNo: apiData?.vehicle?.plate_no,
      driversLicenseNo: apiData?.drivers_license_no,
    }));
  }, [apiData]);

  return (
    <>
      <p className={styles?.productHeader}>
        RIDER AND MOTORCYCLE INFORMATION <span>Enter Rider Information Here</span>
      </p>

      <div className={styles?.form}>
        <div className={styles?.photo}>
          <span>Rider Photo</span>

          <div className={styles?.image}>
            <ImageUpload
              name='riderPhoto'
              setState={setFormData}
              stateValue={formData?.riderPhoto}
            />
          </div>
        </div>

        <div className={styles?.photo}>
          <span>Driver&apos;s License</span>

          <div className={styles?.image}>
            <ImageUpload
              name='driverLicense'
              setState={setFormData}
              stateValue={formData?.driverLicense}
            />
          </div>
        </div>

        <div className={styles?.photo}>
          <span>Motorcycle Photo</span>

          <div className={styles?.image}>
            <ImageUpload
              name='motorcyclePhoto'
              setState={setFormData}
              stateValue={formData?.motorcyclePhoto}
            />
          </div>
        </div>

        <div className={styles?.photo}>
          <span>OR / CR</span>

          <div className={styles?.image}>
            <ImageUpload name='oRCr' setState={setFormData} stateValue={formData?.oRCr} />
          </div>
        </div>
      </div>

      <div className={styles?.form}>
        <div className={styles?.column}>
          <FormText
            type='text'
            name='First Name'
            setState={setFormData}
            placeholder={'Enter First Name'}
            stateValue={formData?.firstName}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='number'
            name='Contact No'
            setState={setFormData}
            haveClass={styles?.fromGroup}
            placeholder={'Enter Contact No.'}
            stateValue={formData?.contactNo}
          />

          <FormText
            type='text'
            name='Vehicle Brand'
            setState={setFormData}
            placeholder={'Enter Vehicle Brand'}
            stateValue={formData?.vehicleBrand}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='text'
            name='Vehicle Plate No'
            setState={setFormData}
            placeholder={'Enter Vehicle Plate No.'}
            stateValue={formData?.vehiclePlateNo}
            haveClass={styles?.fromGroup}
          />
        </div>

        <div className={styles?.column}>
          <FormText
            type='text'
            name='Last Name'
            setState={setFormData}
            placeholder={'Enter Last Name'}
            stateValue={formData?.lastName}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='date'
            name='Birthdate'
            setState={setFormData}
            haveClass={styles?.fromGroup}
            placeholder={'Enter Birthdate.'}
            stateValue={formData?.birthDate}
          />

          <FormText
            type='text'
            name='Vehicle Model'
            setState={setFormData}
            placeholder={'Enter Vehicle Model'}
            stateValue={formData?.vehicleModel}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='text'
            name='OR No.'
            setState={setFormData}
            stateValue={formData?.oRNo}
            placeholder={'Enter OR No..'}
            haveClass={styles?.fromGroup}
          />
        </div>

        <div className={styles?.column}>
          <FormText
            type='email'
            name='Email Address'
            setState={setFormData}
            placeholder={'Enter Email Address'}
            stateValue={formData?.emailAddress}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='number'
            name='Drivers License No.'
            setState={setFormData}
            haveClass={styles?.fromGroup}
            placeholder={`Enter Driver's License No.`}
            stateValue={formData?.driversLicenseNo}
          />

          <FormText
            type='text'
            name='Vehicle Color'
            setState={setFormData}
            placeholder={'Enter Vehicle Color.'}
            stateValue={formData?.vehicleColor}
            haveClass={styles?.fromGroup}
          />

          <FormText
            type='text'
            name='CR No.'
            setState={setFormData}
            placeholder={'Enter CR No.'}
            stateValue={formData?.cRNo}
            haveClass={styles?.fromGroup}
          />
        </div>
      </div>

      <div className={styles?.actions}>
        <Button className={styles?.cancel} onClick={handleCancel}>
          <MinusSVG />
          GO BACK
        </Button>
      </div>
    </>
  );
};

export default Profile;
