/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import FormText from 'components/GlobalUI/FormText';
import FormSelection from 'components/GlobalUI/FormSelection';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { updatePromo, getpromosState, getPromo } from 'reducers/promosReducer';

// Assets
import styles from './index.module.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

const initialState = {
  promoName: '',
  promoCode: '',
  promoPrice: '',
  usageLimit: '',
  promoStart: '',
  promoExpiry: '',
  paymentMethod: '',
};

// Main component
const EditPromo = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const riderState = useSelector(getpromosState);
  const { updateLogs } = riderState;

  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleUpdate = async () => {
    const saveData = {
      name: formData?.promoName,
      code: formData?.promoCode,
      price: formData?.promoPrice,
      usage: formData?.usageLimit,
      end_date: formData?.promoExpiry,
      start_date: formData?.promoStart,
      payment_method: formData?.paymentMethod,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.warning('Please fill in the empty fields');
      return;
    }

    const apiData = new FormData();

    apiData.append('name', saveData?.name);
    apiData.append('code', saveData?.code);
    apiData.append('usage', saveData?.usage);
    apiData.append('price', +saveData?.price);
    apiData.append('end_date', saveData?.end_date);
    apiData.append('start_date', saveData?.start_date);
    apiData.append('payment_method', saveData?.payment_method);

    try {
      const response = await dispatch(updatePromo({ id: formData?.id, params: apiData }));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info('Successfully Updated Promo');
        getPromoInfo();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getPromoInfo = async () => {
    try {
      const response = await dispatch(getPromo(id));

      const newData = {
        ...response?.payload?.data,
        promoName: response?.payload?.data?.name,
        promoCode: response?.payload?.data?.code,
        promoPrice: response?.payload?.data?.price,
        usageLimit: response?.payload?.data?.usage,
        promoStart: response?.payload?.data?.start_date,
        promoExpiry: response?.payload?.data?.end_date,
        paymentMethod: response?.payload?.data?.payment_method,
      };

      setFormData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    if (id) {
      getPromoInfo();
    }
  }, [id]);

  return (
    <PrivateLayout pageTitle='View Promos'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>VIEW PROMO</h4>
              <p>
                <span>PROMO</span> / VIEW PROMO
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addPromo}>
              <p className={styles?.productHeader}>
                ADD PROMO <span>Add New Promo Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Promo Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Name'}
                    stateValue={formData?.promoName}
                  />

                  <FormText
                    type='text'
                    name='Promo Code'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Code'}
                    stateValue={formData?.promoCode}
                  />

                  <FormText
                    type='number'
                    name='Promo Price'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Price'}
                    stateValue={formData?.promoPrice}
                  />

                  <FormText
                    type='number'
                    name='Usage Limit'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Usage Limit'}
                    stateValue={formData?.usageLimit}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='date'
                    name='Promo Start'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Start'}
                    stateValue={formData?.promoStart}
                  />

                  <FormText
                    type='date'
                    name='Promo Expiry'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Expiry'}
                    stateValue={formData?.promoExpiry}
                  />

                  <FormSelection
                    type='text'
                    name='Payment Method'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Payment Method'}
                    stateValue={formData?.paymentMethod}
                    options={[{ value: 'Cash', label: 'Cash' }]}
                  />
                </div>
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleUpdate}
                  className={styles?.add}
                  disabled={updateLogs?.isLoading}
                >
                  <PlusSVG />
                  UPDATE
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  BACK
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default EditPromo;
