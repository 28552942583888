/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';

// Components
import History from './components/History';
import Profile from './components/Profile';
import { getRiderDetails } from 'reducers/riderReducer';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';

// Assets
import styles from './index.module.scss';

// Main component
const ViewRider = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    id: 0,
    firstname: '',
    middlename: '',
    lastname: '',
    email: '',
    contact_no: '',
    birthdate: '',
    drivers_license_no: '',
    status: '',
    account_status: '',
    vehicle: {
      model: '',
      brand: '',
      color: '',
      plate_no: '',
      or_no: '',
      cr_no: '',
    },
    created: {
      string: '',
      human: '',
    },
    files: {
      avatar: '',
      img_drivers_license: '',
      img_motorcycle: '',
      img_or: '',
      img_cr: '',
    },
    deliveries: [],
  });

  const [selectedTab, setSelectedTab] = useState('RIDER PROFILE');

  const profileISActive = selectedTab === 'RIDER PROFILE';
  const historyISActive = selectedTab === 'DELIVERY HISTORY';

  const getRiderInfo = async () => {
    try {
      const response = await dispatch(getRiderDetails(id));

      const newData = {
        ...response?.payload?.data,
        promoName: response?.payload?.data?.name,
        promoCode: response?.payload?.data?.code,
        promoPrice: response?.payload?.data?.price,
        usageLimit: response?.payload?.data?.usage,
        promoStart: response?.payload?.data?.start_date,
        promoExpiry: response?.payload?.data?.end_date,
        paymentMethod: response?.payload?.data?.payment_method,
      };

      setFormData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    if (id) {
      getRiderInfo();
    }
  }, [id]);

  return (
    <PrivateLayout pageTitle='View Rider'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>VIEW RIDER</h4>
              <p>
                <span>RIDER LIST</span> / VIEW RIDER
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.buttons}>
              <Button
                style={{ borderRadius: '15px 0 0 0' }}
                onClick={() => setSelectedTab('RIDER PROFILE')}
                className={profileISActive ? styles?.active : ''}
              >
                RIDER PROFILE
              </Button>
              <Button
                style={{ borderRadius: '0 15px 0 0' }}
                onClick={() => setSelectedTab('DELIVERY HISTORY')}
                className={historyISActive ? styles?.active : ''}
              >
                DELIVERY HISTORY
              </Button>
            </div>
            <div className={styles?.viewRider}>
              {profileISActive && <Profile apiData={formData} />}

              {historyISActive && <History apiData={formData} />}
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default ViewRider;
