/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const login = createAsyncThunk('login', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `/api/login`,
    data: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const merchantLogin = createAsyncThunk(
  'merchantLogin',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api-merchant/login`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const signup = createAsyncThunk('signup', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'post',
    url: `/api/register`,
    data: params,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/password/forgot-password`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updatePassword = createAsyncThunk(
  'updatePassword',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `/api/password/reset`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateProfileData = createAsyncThunk(
  'updateProfileData',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/profile?include[]=content`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateMerchantPassword = createAsyncThunk(
  'updateMerchantPassword',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `/api/merchant-change-password`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
