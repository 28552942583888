/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { logout } from 'reducers/authReducer';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUI/FormText';
import { updateMerchantPassword } from 'reducers/authReducer';
import { getRiders } from 'reducers/riderReducer';

import { removeItem } from 'utils/storage';

import styles from './PasswordPrompt.module.scss';

const initialState = {
  password: '',
  confirmPassword: '',
};

const PasswordPrompt = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const [show, setShow] = useState(false);

  const loginStoreState = useSelector(selectAuth);
  const { userInfo, updateMerchantPasswordLogs } = loginStoreState;

  const onHide = () => {};

  const passwordProps = {
    required: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    haveClass: styles?.formGroup,
    stateValue: formData?.password,
    placeholder: 'Enter your password',
  };

  const confirmPasswordProps = {
    required: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    haveClass: styles?.formGroup,
    placeholder: 'Enter your password',
    stateValue: formData?.confirmPassword,
  };

  const logOut = () => {
    removeItem('accessToken');

    setTimeout(() => {
      dispatch(logout());
      navigate('/');
    }, 100);
  };

  const handleUpdate = async () => {
    const apiData = new FormData();

    apiData.append('merchant_id', userInfo?.id);
    apiData.append('newpassword', formData?.password);
    apiData.append('confirmnewpassword', formData?.confirmPassword);

    try {
      const response = await dispatch(updateMerchantPassword(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        if (response?.payload?.message) {
          toast.error(response?.payload?.message);
          return;
        }

        toast.error('Something went wrong.');
        return;
      }

      if (response) {
        setShow(false);
        setFormData(initialState);
        toast.error('Successfully updated Password');
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    if (userInfo) {
      const { is_first_login } = userInfo;

      if (+is_first_login === 1) {
        setShow(true);
      }
    }
  }, [userInfo]);

  return (
    <Modal
      size='md'
      centered
      onHide={onHide}
      show={show}
      className={styles?.passwordModal}
      aria-labelledby='password-update'
    >
      <Modal.Header className={styles?.header}>
        <p className={styles?.title}>Merchant Password Update</p>
      </Modal.Header>

      <Modal.Body className={styles?.body}>
        <div className={styles?.form}>
          <FormText {...passwordProps} />

          <FormText {...confirmPasswordProps} />
        </div>
        <div className={styles?.buttons}>
          <Button
            onClick={handleUpdate}
            className={styles?.submit}
            disabled={updateMerchantPasswordLogs?.isLoading}
          >
            OK
          </Button>

          <Button className={styles?.close} onClick={logOut}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordPrompt;
