/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button } from 'react-bootstrap';

// Components
import ReactHelmet from './ReactHelmet';
import NavBar from 'components/Layouts/NavBar';
import { selectAuth } from 'reducers/authReducer';
import OrderDetails from 'components/GlobalUI/Modal/OrderDetails/OrderDetails';

// Assets
import 'components/Styles/PrivateLayout.scss';

const PrivateLayout = ({ children, pageTitle }) => {
  const loginStoreState = useSelector(selectAuth);
  const { user_type, isAuthenticated } = loginStoreState;

  const path = window.location.pathname;

  // console.log('path', path);

  return (
    <>
      <ReactHelmet title={pageTitle} />

      <div className='navigation-bar'>
        <NavBar />

        <div className='main-container'>{children}</div>

        {user_type !== 'admin' && path !== '/orders' && <OrderDetails />}
      </div>
    </>
  );
};

export default PrivateLayout;
