/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { updateProfile } from './thunks/userThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  updateLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'sample',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [updateProfile.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateProfile.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: sampleActions, reducer: sampleReducer } = slice;

export const { resetState } = sampleActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.sample || initialState;

export const getSampleState = createSelector([selectDomain], (sampleState) => sampleState);

/* --------------------------------------------------------------------------------- */

export { updateProfile };
