/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import FormText from 'components/GlobalUI/FormText';
import { getProduct } from 'reducers/productReducer';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import FormSelection from 'components/GlobalUI/FormSelection';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

// Main component
const UpdateMerchantProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    price: '',
    inventory: '',
    productName: '',
    availability: '',
  });

  const handleCancel = () => {
    navigate(-1);
  };

  const handleProduct = async () => {
    try {
      const response = await dispatch(getProduct(id));

      setFormData({
        ...response?.payload?.data,
        price: response?.payload?.data?.price,
        productName: response?.payload?.data?.name,
        inventory: response?.payload?.data?.inventory,
        availability: response?.payload?.data?.availability,
      });
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleSave = async () => {
    const apiData = new FormData();

    apiData.append('code', formData?.code);
    apiData.append('photo', formData?.photo);
    apiData.append('price', formData?.price);
    apiData.append('name', formData?.productName);
    apiData.append('description', formData?.description);
    apiData.append('category_id', formData?.category_id);
    apiData.append('subcategory_id', formData?.subcategory_id);
  };

  useEffect(() => {
    if (id) {
      handleProduct();
    }
  }, [id]);

  return (
    <PrivateLayout pageTitle='Update Merchant Product'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>EDIT PRODUCT</h4>
              <p>
                <span>MERCHANT</span> / EDIT PRODUCT
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addProduct}>
              <p className={styles?.productHeader}>
                EDIT PRODUCT <span>Edit Product Details Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Product Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Product Name'}
                    stateValue={formData?.productName}
                  />

                  <FormText
                    type='text'
                    name='Inventory'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Inventory'}
                    stateValue={formData?.inventory}
                  />

                  <FormText
                    type='text'
                    name='Price'
                    setState={setFormData}
                    placeholder={'Enter Price'}
                    stateValue={formData?.price}
                    haveClass={styles?.fromGroup}
                  />
                </div>

                <div className={styles?.column}>
                  <FormSelection
                    type='text'
                    name='Availability'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Availability'}
                    stateValue={formData?.availability}
                    options={[
                      { value: '1', label: 'Available' },
                      { value: '0', label: 'Unavailable' },
                    ]}
                  />
                </div>
              </div>

              <div className={styles?.actions}>
                <Button onClick={handleSave} className={styles?.add}>
                  <CheckSVG />
                  SAVE
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default UpdateMerchantProduct;
