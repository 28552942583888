/* eslint-disable no-unreachable */
/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import FormText from 'components/GlobalUI/FormText';
import MapLocator from 'components/GlobalUI/MapLocator';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import FormSelection from 'components/GlobalUI/FormSelection';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { createMerchant, getMerchantsState } from 'reducers/merchantReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

// Main component
const AddMerchant = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const merchantState = useSelector(getMerchantsState);
  const { submitLogs } = merchantState;

  const [merchants, setMerchants] = useState([]);
  const [merchantCategories, setMerchantCategories] = useState([]);
  const [formData, setFormData] = useState({
    lat: '',
    long: '',
    name: '',
    avatar: '',
    address: '',
    category: '',
    emailAddress: '',
    isMainBranch: '',
    contactNumber: '',
    selectMainBranch: '',
  });

  const handleCancel = () => {
    navigate('/merchants');
  };

  const handleSave = async () => {
    const saveData = {
      lat: formData?.lat,
      long: formData?.long,
      name: formData?.name,
      avatar: formData?.avatar,
      address: formData?.address,
      category: formData?.category,
      email: formData?.emailAddress,
      contact_no: formData?.contactNumber,
      isMainBranch: formData?.isMainBranch,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.warning('Please fill in the empty fields');
      return;
    }

    const apiData = new FormData();

    if (saveData?.isMainBranch === 'No') {
      apiData.append('parent_id', formData?.selectMainBranch);
    } else {
      apiData.append('parent_id', '0');
    }

    apiData.append('category', '1');
    apiData.append('name', saveData?.name);
    apiData.append('email', saveData?.email);
    apiData.append('latitude', saveData?.lat);
    apiData.append('longitude', saveData?.long);
    apiData.append('address', saveData?.address);
    apiData.append('avatar', saveData?.avatar[0]);
    apiData.append('contact_no', saveData?.contact_no);
    apiData.append('merchant_category_id', saveData?.category);

    try {
      const response = await dispatch(createMerchant(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info('Successfully Created Merchant Account');
        setFormData({
          lat: '',
          long: '',
          name: '',
          avatar: '',
          address: '',
          category: '',
          emailAddress: '',
          isMainBranch: '',
          contactNumber: '',
          selectMainBranch: '',
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    const getMerchantCategories = async () => {
      try {
        axios.get('api/merchant-categories?per_page=1000').then((response) => {
          setMerchantCategories(response?.data?.data);
        });
      } catch (error) {
        // console.log('Error', error);
      }
    };

    const getMerchants = async () => {
      try {
        axios.get('api/merchants?per_page=1000').then((response) => {
          setMerchants(response?.data?.data);
        });
      } catch (error) {
        // console.log('Error', error);
      }
    };

    getMerchantCategories();
    getMerchants();
  }, []);

  return (
    <PrivateLayout pageTitle='Add Merhant'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>ADD NEW MERCHANT</h4>
              <p>
                <span>MERCHANT</span> / ADD NEW MERCHANT
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.AddMerchant}>
              <p className={styles?.productHeader}>
                ADD MERCHANT <span>Enter Merchant Information Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.photo}>
                  <span>Avatar</span>
                  <ImageUpload name='avatar' setState={setFormData} stateValue={formData?.avatar} />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Name'}
                    stateValue={formData?.name}
                  />

                  <FormText
                    type='text'
                    name='Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Address'}
                    stateValue={formData?.address}
                  />

                  <FormText
                    type='text'
                    name='Contact Number'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Contact Number'}
                    stateValue={formData?.contactNumber}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Email Address'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Email Address'}
                    stateValue={formData?.emailAddress}
                  />

                  <FormSelection
                    name='Category'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Category'}
                    stateValue={formData?.category}
                    options={merchantCategories.map((category) => {
                      return {
                        value: category?.id,
                        label: category?.name,
                      };
                    })}
                  ></FormSelection>
                </div>
              </div>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <Form.Group>
                    <Form.Label>Set Exact Location</Form.Label>
                    <MapLocator setFormData={setFormData} />
                  </Form.Group>
                </div>

                <div className={styles?.column}>
                  <Form.Group>
                    <FormSelection
                      name='Is Main Branch?'
                      setState={setFormData}
                      haveClass={styles?.fromGroup}
                      placeholder={'Click to Select'}
                      stateValue={formData?.isMainBranch}
                      options={[
                        {
                          value: 'Yes',
                          label: 'Yes',
                        },
                        {
                          value: 'No',
                          label: 'No',
                        },
                      ]}
                    ></FormSelection>
                  </Form.Group>

                  {formData?.isMainBranch === 'No' && (
                    <Form.Group>
                      <FormSelection
                        name='Select Main Branch'
                        setState={setFormData}
                        haveClass={styles?.fromGroup}
                        placeholder={'Click to Select'}
                        stateValue={formData?.selectMainBranch}
                        options={merchants.map((dd) => ({
                          value: dd?.id,
                          label: dd?.name,
                        }))}
                      ></FormSelection>
                    </Form.Group>
                  )}
                </div>
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleSave}
                  className={styles?.add}
                  disabled={submitLogs?.isLoading}
                >
                  <PlusSVG />
                  ADD NEW MERCHANT
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default AddMerchant;
