/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getProduct,
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  deleteSubProduct,
  toggleAvailability,
} from './thunks/productThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  singleData: null,
  singleLogs: initialLog,
  createLogs: initialLog,
  updateLogs: initialLog,
  toggleLogs: initialLog,
  deleteLogs: initialLog,
  deleteSubLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'product',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getProducts.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getProducts.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
    },
    [getProduct.pending]: (state) => {
      state.singleLogs.error = '';
      state.singleLogs.isFailed = false;
      state.singleLogs.isLoading = true;
      state.singleLogs.isSuccess = false;
    },
    [getProduct.rejected]: (state, action) => {
      state.singleLogs.isFailed = true;
      state.singleLogs.isLoading = false;
      state.singleLogs.error = action?.payload?.errors[0]?.detail;
    },
    [createProduct.fulfilled]: (state, action) => {
      state.createLogs.isSuccess = true;
      state.createLogs.isLoading = false;
    },
    [createProduct.pending]: (state) => {
      state.createLogs.error = '';
      state.createLogs.isFailed = false;
      state.createLogs.isLoading = true;
      state.createLogs.isSuccess = false;
    },
    [createProduct.rejected]: (state, action) => {
      state.createLogs.isFailed = true;
      state.createLogs.isLoading = false;
      state.createLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateProduct.fulfilled]: (state, action) => {
      state.updateLogs.isSuccess = true;
      state.updateLogs.isLoading = false;
    },
    [updateProduct.pending]: (state) => {
      state.updateLogs.error = '';
      state.updateLogs.isFailed = false;
      state.updateLogs.isLoading = true;
      state.updateLogs.isSuccess = false;
    },
    [updateProduct.rejected]: (state, action) => {
      state.updateLogs.isFailed = true;
      state.updateLogs.isLoading = false;
      state.updateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteSubProduct.fulfilled]: (state, action) => {
      state.deleteSubLogs.isSuccess = true;
      state.deleteSubLogs.isLoading = false;
    },
    [deleteSubProduct.pending]: (state) => {
      state.deleteSubLogs.error = '';
      state.deleteSubLogs.isFailed = false;
      state.deleteSubLogs.isLoading = true;
      state.deleteSubLogs.isSuccess = false;
    },
    [deleteSubProduct.rejected]: (state, action) => {
      state.deleteSubLogs.isFailed = true;
      state.deleteSubLogs.isLoading = false;
      state.deleteSubLogs.error = action?.payload?.errors[0]?.detail;
    },
    [toggleAvailability.fulfilled]: (state, action) => {
      state.toggleLogs.isSuccess = true;
      state.toggleLogs.isLoading = false;
    },
    [toggleAvailability.pending]: (state) => {
      state.toggleLogs.error = '';
      state.toggleLogs.isFailed = false;
      state.toggleLogs.isLoading = true;
      state.toggleLogs.isSuccess = false;
    },
    [toggleAvailability.rejected]: (state, action) => {
      state.toggleLogs.isFailed = true;
      state.toggleLogs.isLoading = false;
      state.toggleLogs.error = action?.payload?.errors[0]?.detail;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.deleteLogs.isSuccess = true;
      state.deleteLogs.isLoading = false;
    },
    [deleteProduct.pending]: (state) => {
      state.deleteLogs.error = '';
      state.deleteLogs.isFailed = false;
      state.deleteLogs.isLoading = true;
      state.deleteLogs.isSuccess = false;
    },
    [deleteProduct.rejected]: (state, action) => {
      state.deleteLogs.isFailed = true;
      state.deleteLogs.isLoading = false;
      state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: productActions, reducer: productReducer } = slice;

export const { resetState } = productActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.product || initialState;

export const getProductState = createSelector([selectDomain], (productState) => productState);

/* --------------------------------------------------------------------------------- */

export {
  getProduct,
  getProducts,
  deleteProduct,
  createProduct,
  updateProduct,
  deleteSubProduct,
  toggleAvailability,
};
