/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
import React from 'react';
import { Pagination } from 'react-bootstrap';

import '../Styles/TablePagination.scss';

const TablePagination = ({ meta, loading, setParams, handlePaginationClick }) => {
  // if (!meta)
  //   return (
  //     <div className='table-pagination'>
  //       <p>Loading...</p>
  //     </div>
  //   );

  // const { current_page, last_page } = meta;

  const handleCurrentPage = () => {
    if (!meta?.current_page) return '';

    return (
      <p>
        Showing page {meta?.current_page} of {meta?.last_page}
      </p>
    );
  };

  const handlePageChange = (pp) => {
    setParams((prev) => {
      return {
        ...prev,
        page: pp,
      };
    });

    handlePaginationClick({
      page: pp,
    });
  };

  const canPreviousPage = meta?.current_page !== 1;
  const canNextPage = meta?.current_page !== meta?.last_page;

  return (
    <div className='table-pagination'>
      <Pagination>
        <Pagination.First disabled={loading} onClick={() => handlePageChange(1)} className='box' />

        <Pagination.Prev
          className='box'
          disabled={loading || !canPreviousPage}
          onClick={() => handlePageChange(parseInt(meta?.current_page) - 1)}
        />

        {canPreviousPage && (
          <Pagination.Item
            disabled={loading}
            onClick={() => handlePageChange(parseInt(meta?.current_page) - 1)}
          >
            {meta?.current_page - 1}
          </Pagination.Item>
        )}

        <Pagination.Item active>{meta?.current_page}</Pagination.Item>

        {canNextPage && (
          <Pagination.Item
            disabled={loading}
            onClick={() => handlePageChange(parseInt(meta?.current_page) + 1)}
          >
            {meta?.current_page + 1}
          </Pagination.Item>
        )}

        <Pagination.Next
          disabled={loading || !canNextPage}
          onClick={() => handlePageChange(parseInt(meta?.current_page) + 1)}
          className='box'
        />

        <Pagination.Last
          className='box'
          disabled={loading}
          onClick={() => handlePageChange(meta?.last_page)}
        />
      </Pagination>

      {handleCurrentPage()}
    </div>
  );
};

export default TablePagination;
