/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';

// Components
import { checkEmpty } from 'utils/validator';
import FormText from 'components/GlobalUI/FormText';
import ImageUpload from 'components/GlobalUI/ImageUpload';
import FormSelection from 'components/GlobalUI/FormSelection';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import { createPromo, getpromosState } from 'reducers/promosReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as MinusSVG } from 'assets/svg/xmark.svg';

const initialState = {
  promoName: '',
  promoCode: '',
  promoPrice: '',
  usageLimit: '',
  promoStart: '',
  promoExpiry: '',
  paymentMethod: '',
};

// Main component
const AddPromos = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const riderState = useSelector(getpromosState);
  const { createLogs } = riderState;

  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSave = async () => {
    const saveData = {
      name: formData?.promoName,
      code: formData?.promoCode,
      price: formData?.promoPrice,
      usage: formData?.usageLimit,
      end_date: formData?.promoExpiry,
      start_date: formData?.promoStart,
      payment_method: formData?.paymentMethod,
    };

    let emptyCheck = false;
    emptyCheck = checkEmpty(saveData);

    if (emptyCheck) {
      toast.warning('Please fill in the empty fields');
      return;
    }

    const apiData = new FormData();

    apiData.append('name', saveData?.name);
    apiData.append('code', saveData?.code);
    apiData.append('usage', saveData?.usage);
    apiData.append('price', saveData?.price);
    apiData.append('end_date', saveData?.end_date);
    apiData.append('start_date', saveData?.start_date);
    apiData.append('payment_method', saveData?.payment_method);

    try {
      const response = await dispatch(createPromo(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info('Successfully Created Promo');
        setFormData(initialState);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <PrivateLayout pageTitle='Add Promos'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>ADD PROMO</h4>
              <p>
                <span>PROMO</span> / ADD PROMO
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.addProduct}>
              <p className={styles?.productHeader}>
                ADD PROMO <span>Add New Promo Here</span>
              </p>

              <div className={styles?.form}>
                <div className={styles?.column}>
                  <FormText
                    type='text'
                    name='Promo Name'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Name'}
                    stateValue={formData?.promoName}
                  />

                  <FormText
                    type='text'
                    name='Promo Code'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Code'}
                    stateValue={formData?.promoCode}
                  />

                  <FormText
                    type='number'
                    name='Promo Price'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Price'}
                    stateValue={formData?.promoPrice}
                  />

                  <FormText
                    type='number'
                    name='Usage Limit'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Usage Limit'}
                    stateValue={formData?.usageLimit}
                  />
                </div>

                <div className={styles?.column}>
                  <FormText
                    type='date'
                    name='Promo Start'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Start'}
                    stateValue={formData?.promoStart}
                  />

                  <FormText
                    type='date'
                    name='Promo Expiry'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Promo Expiry'}
                    stateValue={formData?.promoExpiry}
                  />

                  <FormSelection
                    type='text'
                    name='Payment Method'
                    setState={setFormData}
                    haveClass={styles?.fromGroup}
                    placeholder={'Enter Payment Method'}
                    stateValue={formData?.paymentMethod}
                    options={[{ value: 'Cash', label: 'Cash' }]}
                  />
                </div>
              </div>

              <div className={styles?.actions}>
                <Button
                  onClick={handleSave}
                  className={styles?.add}
                  disabled={createLogs?.isLoading}
                >
                  <PlusSVG />
                  ADD
                </Button>
                <Button className={styles?.cancel} onClick={handleCancel}>
                  <MinusSVG />
                  CANCEL
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default AddPromos;
