/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getMerchant,
  getMerchants,
  createMerchant,
  generateReport,
  uploadMasterFile,
  getMerchantProducts,
  toggleStatusMerchant,
  toggleFeaturedMerchant,
} from './thunks/merchantThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  singleData: null,
  singleIncluded: null,
  masterLogs: initialLog,
  submitLogs: initialLog,
  updateLogs: initialLog,
  deleteLogs: initialLog,
  singleLogs: initialLog,
  statusLogs: initialLog,
  featuredLogs: initialLog,
  productData: [],
  productMeta: null,
  productLogs: initialLog,
  generateLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'merchants',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [generateReport.fulfilled]: (state, action) => {
      state.generateLogs.isSuccess = true;
      state.generateLogs.isLoading = false;
    },
    [generateReport.pending]: (state) => {
      state.generateLogs.error = '';
      state.generateLogs.isFailed = false;
      state.generateLogs.isLoading = true;
      state.generateLogs.isSuccess = false;
    },
    [generateReport.rejected]: (state, action) => {
      state.generateLogs.isFailed = true;
      state.generateLogs.isLoading = false;
      state.generateLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getMerchants.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getMerchants.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getMerchants.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [createMerchant.fulfilled]: (state, action) => {
      state.submitLogs.isSuccess = true;
      state.submitLogs.isLoading = false;
    },
    [createMerchant.pending]: (state) => {
      state.submitLogs.error = '';
      state.submitLogs.isFailed = false;
      state.submitLogs.isLoading = true;
      state.submitLogs.isSuccess = false;
    },
    [createMerchant.rejected]: (state, action) => {
      state.submitLogs.isFailed = true;
      state.submitLogs.isLoading = false;
      state.submitLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getMerchant.fulfilled]: (state, action) => {
      state.singleLogs.isSuccess = true;
      state.singleLogs.isLoading = false;
      state.singleData = action.payload.data;
      state.singleIncluded = action.payload.included;
    },
    [getMerchant.pending]: (state) => {
      state.singleLogs.error = '';
      state.singleLogs.isFailed = false;
      state.singleLogs.isLoading = true;
      state.singleLogs.isSuccess = false;
    },
    [getMerchant.rejected]: (state, action) => {
      state.singleLogs.isFailed = true;
      state.singleLogs.isLoading = false;
      state.singleLogs.error = action?.payload?.errors[0]?.detail;
    },
    [uploadMasterFile.fulfilled]: (state, action) => {
      state.masterLogs.isSuccess = true;
      state.masterLogs.isLoading = false;
    },
    [uploadMasterFile.pending]: (state) => {
      state.masterLogs.error = '';
      state.masterLogs.isFailed = false;
      state.masterLogs.isLoading = true;
      state.masterLogs.isSuccess = false;
    },
    [uploadMasterFile.rejected]: (state, action) => {
      state.masterLogs.isFailed = true;
      state.masterLogs.isLoading = false;
      state.masterLogs.error = action?.payload?.errors[0]?.detail;
    },
    [toggleStatusMerchant.fulfilled]: (state, action) => {
      state.statusLogs.isSuccess = true;
      state.statusLogs.isLoading = false;
    },
    [toggleStatusMerchant.pending]: (state) => {
      state.statusLogs.error = '';
      state.statusLogs.isFailed = false;
      state.statusLogs.isLoading = true;
      state.statusLogs.isSuccess = false;
    },
    [toggleStatusMerchant.rejected]: (state, action) => {
      state.statusLogs.isFailed = true;
      state.statusLogs.isLoading = false;
      state.statusLogs.error = action?.payload?.errors[0]?.detail;
    },
    [toggleFeaturedMerchant.fulfilled]: (state, action) => {
      state.featuredLogs.isSuccess = true;
      state.featuredLogs.isLoading = false;
    },
    [toggleFeaturedMerchant.pending]: (state) => {
      state.featuredLogs.error = '';
      state.featuredLogs.isFailed = false;
      state.featuredLogs.isLoading = true;
      state.featuredLogs.isSuccess = false;
    },
    [toggleFeaturedMerchant.rejected]: (state, action) => {
      state.featuredLogs.isFailed = true;
      state.featuredLogs.isLoading = false;
      state.featuredLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getMerchantProducts.fulfilled]: (state, action) => {
      state.productLogs.isSuccess = true;
      state.productLogs.isLoading = false;
      state.productData = action.payload.data;
      state.productMeta = action.payload.meta;
    },
    [getMerchantProducts.pending]: (state) => {
      state.productLogs.error = '';
      state.productLogs.isFailed = false;
      state.productLogs.isLoading = true;
      state.productLogs.isSuccess = false;
    },
    [getMerchantProducts.rejected]: (state, action) => {
      state.productLogs.isFailed = true;
      state.productLogs.isLoading = false;
      state.productLogs.error = action?.payload?.errors[0]?.detail;
    },
    // [sampleDeleteAPI.fulfilled]: (state, action) => {
    //   state.deleteLogs.isSuccess = true;
    //   state.deleteLogs.isLoading = false;
    // },
    // [sampleDeleteAPI.pending]: (state) => {
    //   state.deleteLogs.error = '';
    //   state.deleteLogs.isFailed = false;
    //   state.deleteLogs.isLoading = true;
    //   state.deleteLogs.isSuccess = false;
    // },
    // [sampleDeleteAPI.rejected]: (state, action) => {
    //   state.deleteLogs.isFailed = true;
    //   state.deleteLogs.isLoading = false;
    //   state.deleteLogs.error = action?.payload?.errors[0]?.detail;
    // },
  },
});

export const { actions: merchantsActions, reducer: merchantsReducer } = slice;

export const { resetState } = merchantsActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.merchants || initialState;

export const getMerchantsState = createSelector([selectDomain], (merchantsState) => merchantsState);

/* --------------------------------------------------------------------------------- */

export {
  getMerchant,
  getMerchants,
  generateReport,
  createMerchant,
  uploadMasterFile,
  getMerchantProducts,
  toggleStatusMerchant,
  toggleFeaturedMerchant,
};
