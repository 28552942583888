/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOrders = createAsyncThunk(
  'getOrders',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/merchant-product-list/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAudits = createAsyncThunk(
  'getAudits',
  async ({ id, params }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/merchant-audit-list/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getAllOrders = createAsyncThunk(
  'getAllOrders',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/transactions-by-merchant/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const cancelOrder = createAsyncThunk('cancelOrder', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `api/merchant/cancel-order`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const acceptOrder = createAsyncThunk('acceptOrder', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `api/merchant/accept-order`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const exportAudit = createAsyncThunk('exportAudit', async (params, { rejectWithValue }) => {
  return await axios({
    data: params,
    method: 'post',
    url: `api/merchant-audit-export-excel`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

// export const getMerchant = createAsyncThunk(
//   'getMerchant',
//   async (id, { rejectWithValue }) => {
//     return await axios({
//       method: 'get',
//       url: `api/merchants/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const samplePutAPI = createAsyncThunk(
//   'samplePutAPI',
//   async (params, { rejectWithValue }) => {
//     return await axios({
//       method: 'put',
//       url: `sample-url`,
//       data: params,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const sampleDeleteAPI = createAsyncThunk(
//   'sampleDeleteAPI',
//   async (id, { rejectWithValue }) => {
//     return await axios({
//       method: 'delete',
//       url: `sample-url/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );
