/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getOrders,
  getAudits,
  cancelOrder,
  acceptOrder,
  exportAudit,
  getAllOrders,
} from './thunks/orderThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  data: [],
  meta: null,
  included: [],
  logs: initialLog,
  cancelLogs: initialLog,
  updateLogs: initialLog,
  acceptLogs: initialLog,
  deleteLogs: initialLog,
  singleData: null,
  singleIncluded: null,
  singleLogs: initialLog,
  allData: [],
  allMeta: null,
  allLogs: initialLog,
  auditLogs: initialLog,
  auditData: [],
  auditMeta: null,
  exportAuditLog: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'orders',
  reducers: {
    resetState: (state, action) => {
      state.data = [];
      state.meta = null;
      state.included = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getAudits.fulfilled]: (state, action) => {
      state.auditLogs.isSuccess = true;
      state.auditLogs.isLoading = false;
      state.auditData = action.payload.data;
      state.auditMeta = action.payload.meta;
    },
    [getAudits.pending]: (state) => {
      state.auditLogs.error = '';
      state.auditLogs.isFailed = false;
      state.auditLogs.isLoading = true;
      state.auditLogs.isSuccess = false;
    },
    [getAudits.rejected]: (state, action) => {
      state.auditLogs.isFailed = true;
      state.auditLogs.isLoading = false;
      state.auditLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.logs.isSuccess = true;
      state.logs.isLoading = false;
      state.data = action.payload.data;
      state.meta = action.payload.meta;
      state.included = action.payload.included;
    },
    [getOrders.pending]: (state) => {
      state.logs.error = '';
      state.logs.isFailed = false;
      state.logs.isLoading = true;
      state.logs.isSuccess = false;
    },
    [getOrders.rejected]: (state, action) => {
      state.logs.isFailed = true;
      state.logs.isLoading = false;
      state.logs.error = action?.payload?.errors[0]?.detail;
    },
    [cancelOrder.fulfilled]: (state, action) => {
      state.cancelLogs.isSuccess = true;
      state.cancelLogs.isLoading = false;
    },
    [cancelOrder.pending]: (state) => {
      state.cancelLogs.error = '';
      state.cancelLogs.isFailed = false;
      state.cancelLogs.isLoading = true;
      state.cancelLogs.isSuccess = false;
    },
    [cancelOrder.rejected]: (state, action) => {
      state.cancelLogs.isFailed = true;
      state.cancelLogs.isLoading = false;
      state.cancelLogs.error = action?.payload?.errors[0]?.detail;
    },
    [acceptOrder.fulfilled]: (state, action) => {
      state.acceptLogs.isSuccess = true;
      state.acceptLogs.isLoading = false;
    },
    [acceptOrder.pending]: (state) => {
      state.acceptLogs.error = '';
      state.acceptLogs.isFailed = false;
      state.acceptLogs.isLoading = true;
      state.acceptLogs.isSuccess = false;
    },
    [acceptOrder.rejected]: (state, action) => {
      state.acceptLogs.isFailed = true;
      state.acceptLogs.isLoading = false;
      state.acceptLogs.error = action?.payload?.errors[0]?.detail;
    },
    [getAllOrders.fulfilled]: (state, action) => {
      state.allLogs.isSuccess = true;
      state.allLogs.isLoading = false;
      state.allData = action.payload.data;
      state.allMeta = action.payload.meta;
    },
    [getAllOrders.pending]: (state) => {
      state.allLogs.error = '';
      state.allLogs.isFailed = false;
      state.allLogs.isLoading = true;
      state.allLogs.isSuccess = false;
    },
    [getAllOrders.rejected]: (state, action) => {
      state.allLogs.isFailed = true;
      state.allLogs.isLoading = false;
      state.allLogs.error = action?.payload?.errors[0]?.detail;
    },
    [exportAudit.fulfilled]: (state, action) => {
      state.exportAuditLog.isSuccess = true;
      state.exportAuditLog.isLoading = false;
    },
    [exportAudit.pending]: (state) => {
      state.exportAuditLog.error = '';
      state.exportAuditLog.isFailed = false;
      state.exportAuditLog.isLoading = true;
      state.exportAuditLog.isSuccess = false;
    },
    [exportAudit.rejected]: (state, action) => {
      state.exportAuditLog.isFailed = true;
      state.exportAuditLog.isLoading = false;
      state.exportAuditLog.error = action?.payload?.errors[0]?.detail;
    },
  },
});

export const { actions: ordersActions, reducer: ordersReducer } = slice;

export const { resetState } = ordersActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.orders || initialState;

export const getOrdersState = createSelector([selectDomain], (ordersState) => ordersState);

/* --------------------------------------------------------------------------------- */

export { getOrders, cancelOrder, acceptOrder, getAllOrders, exportAudit, getAudits };
