/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table, OverlayTrigger, Popover } from 'react-bootstrap';

// Components
import ImageUI from 'components/GlobalUI/ImageUI';
import { selectAuth } from 'reducers/authReducer';
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import TablePagination from 'components/GlobalUI/TablePagination';
import ConfirmAction from 'components/GlobalUI/Modal/ConfirmAction/ConfirmAction';
import PasswordPrompt from 'components/GlobalUI/Modal/PasswordPrompt/PasswordPrompt';
import {
  getProducts,
  deleteProduct,
  getProductState,
  toggleAvailability,
} from 'reducers/productReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

// Main component
const Products = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef(null);

  const [tableData, setTableData] = useState([]);

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const productState = useSelector(getProductState);
  const { logs, meta } = productState;

  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
  });

  const handleAddProduct = () => {
    navigate('/products/add-product');
  };

  const handleEdit = (data) => {
    if (!data?.id) return;

    navigate(`/products/edit-product/${data?.id}`);
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      search: filter?.search,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleProducts = () => {
    const unfinalParams = {
      page: filter?.page,
      search: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        status: filter?.status,
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const tryCatch = async (params) => {
    setTableData([]);

    try {
      const response = await dispatch(getProducts({ params: params, id: userInfo?.id }));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          foodCode: dd?.code,
          itemName: dd?.name,
          price: `Php ${dd?.price}`,
          isAvailable: dd?.availability,
          productImage: dd?.images?.photo,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleToggle = async (e, dd) => {
    const { checked } = e.target;

    const apiData = new FormData();

    apiData.append('product_id', dd?.id);
    apiData.append('availability', checked ? 1 : 0);

    try {
      const response = await dispatch(toggleAvailability(apiData));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info(`Successfully ${checked ? 'Enabled' : 'Disabled'} Availability`);
        handleProducts();
      }
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleDelete = async (data) => {
    try {
      const response = await dispatch(deleteProduct(data?.id));

      if (response?.meta?.requestStatus === 'rejected') {
        toast.error(response?.payload?.errors[0]?.detail);
        return;
      }

      if (response) {
        toast.info('Successfully deleted product');
        handleProducts();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  useEffect(() => {
    if (userInfo) {
      handleProducts();
    }
  }, [userInfo]);

  return (
    <PrivateLayout pageTitle='Products'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>PRODUCTS</h4>
              <p>
                <span>PRODUCTS</span> / PRODUCTS LIST
              </p>
            </div>

            <div className={styles?.actions}>
              <Button onClick={handleAddProduct}>
                <p>Add New Product</p> <PlusSVG />
              </Button>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                PRODUCT LIST <span>List of Products</span>
              </p>

              <Table striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Product Image</th>
                    <th>Food Code</th>
                    <th>Item Name</th>
                    <th>Price</th>
                    <th>Availability</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((dd) => (
                      <tr key={dd?.id}>
                        <td>
                          <ImageUI src={dd?.productImage}></ImageUI>
                        </td>
                        <td>{dd?.foodCode}</td>
                        <td>{dd?.itemName}</td>
                        <td>{dd?.price}</td>
                        <td>
                          <Form.Check
                            type='switch'
                            checked={dd?.isAvailable}
                            className={styles?.switch}
                            onChange={(e) => handleToggle(e, dd)}
                          />
                        </td>
                        <td>
                          <OverlayTrigger
                            trigger='click'
                            placement='left'
                            rootClose
                            overlay={
                              <Popover id='popover-table-action'>
                                <Popover.Body className='d-flex justify-content-between p-0'>
                                  <div className='button-actions'>
                                    <Button onClick={() => handleEdit(dd)} className='edit'>
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() => {
                                        setShowConfirm({
                                          data: dd,
                                          show: true,
                                          submit: handleDelete,
                                          type: 'delete product?',
                                        });
                                      }}
                                      className='delete'
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant='secondary'
                              className='popoverButton bg-transparent p-0 border-0'
                            >
                              <ActionSVG className={styles?.action} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
              </Table>

              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmAction
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />

      <PasswordPrompt />
    </PrivateLayout>
  );
};

export default Products;
