/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTransactions = createAsyncThunk(
  'getTransactions',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/transactions`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// export const createPromo = createAsyncThunk('createPromo', async (params, { rejectWithValue }) => {
//   return await axios({
//     data: params,
//     method: 'post',
//     url: `api/promos`,
//     baseURL: process.env.REACT_APP_API_BASE_URL,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return rejectWithValue(error.response.data);
//     });
// });

// export const getPromo = createAsyncThunk('getPromo', async (id, { rejectWithValue }) => {
//   return await axios({
//     method: 'get',
//     url: `api/promos/${id}`,
//     baseURL: process.env.REACT_APP_API_BASE_URL,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       return rejectWithValue(error.response.data);
//     });
// });

// export const updatePromo = createAsyncThunk(
//   'updatePromo',
//   async ({ id, params }, { rejectWithValue }) => {
//     return await axios({
//       data: params,
//       method: 'post',
//       url: `api/promos/${id}?_method=PUT`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const samplePutAPI = createAsyncThunk(
//   'samplePutAPI',
//   async (params, { rejectWithValue }) => {
//     return await axios({
//       method: 'put',
//       url: `sample-url`,
//       data: params,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

export const deletePromo = createAsyncThunk('deletePromo', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/promos/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
