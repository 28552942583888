/* eslint-disable no-unused-vars */

import { useState, useEffect } from 'react';

import HistoryTable from './components/HistoryTable';
import ViewCustomer from './components/ViewCustomer';

import styles from './index.module.scss';

const History = ({ apiData }) => {
  const [showScreen, setShowScreen] = useState({
    show: 1,
    data: null,
  });

  const showHistory = showScreen?.show === 1;
  const showCustomer = showScreen?.show === 2;

  return (
    <>
      {showHistory && <HistoryTable setShowScreen={setShowScreen} />}
      {showCustomer && <ViewCustomer showScreen={showScreen} setShowScreen={setShowScreen} />}
    </>
  );
};

export default History;
