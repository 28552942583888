/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Popover, OverlayTrigger, Table } from 'react-bootstrap';

import { objectCleaner } from 'utils/objectCleaner';
import { getRiderDeliveries, getridersState } from 'reducers/riderReducer';
import TablePagination from 'components/GlobalUI/TablePagination';

import styles from './index.module.scss';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';

const sampleData = [
  {
    id: 1,
    orderID: 'Product 1',
    category: 'Food',
    merchantName: 'Tacobell',
    customerName: 'Jual Santos',
    orderTotal: 'Php 1,000.00',
    dateAndTime: 'June 1, 2023 | 8:00 AM',
    status: 'Completed',
  },
  {
    id: 2,
    orderID: 'Product 2',
    category: 'Food',
    merchantName: 'Jollibee',
    customerName: 'Jake Santos',
    orderTotal: 'Php 1,000.00',
    dateAndTime: 'June 1, 2023 | 8:00 AM',
    status: 'Completed',
  },
  {
    id: 3,
    orderID: 'Product 3',
    category: 'Food',
    merchantName: 'McDonalds',
    customerName: 'Mikee Santos',
    orderTotal: 'Php 1,000.00',
    dateAndTime: 'June 1, 2023 | 8:00 AM',
    status: 'Completed',
  },
  {
    id: 4,
    orderID: 'Product 4',
    category: 'Food',
    merchantName: 'KFC',
    customerName: 'Anna Cruz',
    orderTotal: 'Php 1,000.00',
    dateAndTime: 'June 1, 2023 | 8:00 AM',
    status: 'Completed',
  },
  {
    id: 5,
    orderID: 'Product 5',
    category: 'Food',
    merchantName: 'Burger King',
    customerName: 'Jasmine Cruz',
    orderTotal: 'Php 1,000.00',
    dateAndTime: 'June 1, 2023 | 8:00 AM',
    status: 'Completed',
  },
];

const HistoryTable = ({ setShowScreen }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const { id } = params;

  const navigate = useNavigate();

  const riderState = useSelector(getridersState);
  const { deliveriesMeta, deliveriesLogs, deliveriesIncluded } = riderState;

  const [tableData, setTableData] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    status: 'delivered',
  });

  const handleView = (data) => {
    setShowScreen({
      show: 2,
      data: data,
    });
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      rider_id: id,
      per_page: 9999,
      search: filter?.search,
      status: filter?.status,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleDeliveryHistory = () => {
    const unfinalParams = {
      rider_id: id,
      per_page: 9999,
      page: filter?.page,
      search: filter?.search,
      status: filter?.status,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const tryCatch = async (params) => {
    setTableData([]);

    try {
      const response = await dispatch(getRiderDeliveries(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          status: dd?.status,
          orderTotal: dd?.total,
          orderID: dd?.order_no,
          dateAndTime: dd?.created_date,
          customerName: dd?.customer_name,
          merchantName: dd?.merchant?.name,
          category: dd?.merchant?.merchant_category_id,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  useEffect(() => {
    if (id) {
      handleDeliveryHistory();
    }
  }, [id]);

  return (
    <>
      <p className={styles?.productHeader}>DELIVERY HISTORY</p>

      <div className={styles?.table}>
        <Table responsive striped bordered hover className={styles?.table}>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Category</th>
              <th>Merchant Name</th>
              <th>Customer Name</th>
              <th>Order Total</th>
              <th>Date and Time</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData &&
              tableData.map((dd) => (
                <tr key={dd?.id}>
                  <td>{dd?.orderID}</td>
                  <td>{dd?.category}</td>
                  <td>{dd?.merchantName}</td>
                  <td>{dd?.customerName}</td>
                  <td>{dd?.orderTotal}</td>
                  <td>{dd?.dateAndTime}</td>
                  <td>
                    <span className={styles[dd?.status]}>{dd?.status}</span>
                  </td>
                  <td>
                    <OverlayTrigger
                      trigger='click'
                      placement='left'
                      rootClose
                      overlay={
                        <Popover id='popover-table-action'>
                          <Popover.Body className='d-flex justify-content-between p-0'>
                            <div className='button-actions'>
                              <Button onClick={() => handleView(dd)} className='edit'>
                                View Details
                              </Button>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        variant='secondary'
                        className='popoverButton bg-transparent p-0 border-0'
                      >
                        <ActionSVG className={styles?.action} />
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
            <tr></tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Total Earnings</td>
              <td colSpan={4}>{`Php ${deliveriesIncluded}`}</td>
            </tr>
          </tfoot>
        </Table>

        {/* <TablePagination
          meta={deliveriesMeta}
          setParams={setFilter}
          loading={deliveriesLogs?.isLoading}
          handlePaginationClick={handlePaginationClick}
        /> */}
      </div>
    </>
  );
};

export default HistoryTable;
