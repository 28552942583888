/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getMerchants = createAsyncThunk(
  'getMerchants',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/merchants`,
      params: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const createMerchant = createAsyncThunk(
  'createMerchant',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/merchants`,
      data: params,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getMerchant = createAsyncThunk('getMerchant', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    url: `api/merchants/${id}?include[0]=products&include[1]=store_hours&include[2]=category`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getMerchantProducts = createAsyncThunk(
  'getMerchantProducts',
  async ({ params, id }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params,
      url: `api/merchant-product-list/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const uploadMasterFile = createAsyncThunk(
  'uploadMasterFile',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/upload-merchants`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const toggleStatusMerchant = createAsyncThunk(
  'toggleStatusMerchant',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/merchant-update-status`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const toggleFeaturedMerchant = createAsyncThunk(
  'toggleFeaturedMerchant',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      url: `api/merchant-visibility-status`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const generateReport = createAsyncThunk(
  'generateReport',
  async (params, { rejectWithValue }) => {
    return await axios({
      data: params,
      method: 'post',
      responseType: 'blob',
      url: `api/merchant-transactions-generate-csv`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

// export const samplePutAPI = createAsyncThunk(
//   'samplePutAPI',
//   async (params, { rejectWithValue }) => {
//     return await axios({
//       method: 'put',
//       url: `sample-url`,
//       data: params,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );

// export const sampleDeleteAPI = createAsyncThunk(
//   'sampleDeleteAPI',
//   async (id, { rejectWithValue }) => {
//     return await axios({
//       method: 'delete',
//       url: `sample-url/${id}`,
//       baseURL: process.env.REACT_APP_API_BASE_URL,
//     })
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         return rejectWithValue(error.response.data);
//       });
//   },
// );
