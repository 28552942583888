/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px',
};

const MapLocator = ({ setFormData, lat = 14.5790258, lng = 121.0461836 }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCSbqyd-u6mYyBFZsoDiX3Nt2IDveRQz2U',
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleAddress = (e) => {

    setFormData((prev) => ({
      ...prev,
      lat: e.latLng.lat(),
      long: e.latLng.lng(),
    }));
  };

  const center = {
    lat: 14.5790258,
    lng: 121.0461836,
  };

  return isLoaded ? (
    <GoogleMap
      zoom={10}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={containerStyle}
    >
      <Marker position={center} clickable draggable onDragEnd={handleAddress} />
    </GoogleMap>
  ) : null;
};

export default React.memo(MapLocator);
