/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table, Dropdown } from 'react-bootstrap';

// Components
import { selectAuth } from 'reducers/authReducer';
import ImageUI from 'components/GlobalUI/ImageUI';
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import TablePagination from 'components/GlobalUI/TablePagination';
import SamplePagination from 'components/GlobalUI/SamplePagination';
import { getAudits, getOrdersState, exportAudit } from 'reducers/orderReducer';

// Assets
import styles from './index.module.scss';
import sampleImage from 'assets/images/placeholder.png';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as ExportSVG } from 'assets/svg/file-export.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

const sampleData = [
  {
    id: 1,
    orderID: 'ORID-0001',
    foodCode: 'KNE-0001',
    customerName: 'Rodolfo Dela Cruz',
    riderName: 'Juan Dela Cruz',
    price: 'Php 1,000.00',
    date: 'Juan Dela Cruz',
  },
  {
    id: 2,
    orderID: 'ORID-0002',
    foodCode: 'KNE-0002',
    customerName: 'Rodolfo Dela Cruz',
    riderName: 'Juan Dela Cruz',
    price: 'Php 1,000.00',
    date: 'Juan Dela Cruz',
  },
  {
    id: 3,
    orderID: 'ORID-0003',
    foodCode: 'KNE-0003',
    customerName: 'Rodolfo Dela Cruz',
    riderName: 'Juan Dela Cruz',
    price: 'Php 1,000.00',
    date: 'Juan Dela Cruz',
  },
  {
    id: 4,
    orderID: 'ORID-0004',
    foodCode: 'KNE-0004',
    customerName: 'Rodolfo Dela Cruz',
    riderName: 'Juan Dela Cruz',
    price: 'Php 1,000.00',
    date: 'Juan Dela Cruz',
  },
  {
    id: 5,
    orderID: 'ORID-0005',
    foodCode: 'KNE-0005',
    customerName: 'Rodolfo Dela Cruz',
    riderName: 'Juan Dela Cruz',
    price: 'Php 1,000.00',
    date: 'Juan Dela Cruz',
  },
];

// Main component
const Audit = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef(null);

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const orderState = useSelector(getOrdersState);
  const { auditMeta, auditData, exportAuditLog } = orderState;

  const [tableData, setTableData] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    sortBy: '',
    per_page: 5,
  });

  const handleExport = async () => {
    const apiData = new FormData();
    apiData.append('merchantId', userInfo?.id);

    try {

      const res = await dispatch(exportAudit(apiData));

      if (res?.meta?.requestStatus === 'rejected') {
        toast.error(res?.payload?.errors[0]?.detail);
        return;
      }

      if (res) {
        const url = window.URL.createObjectURL(new Blob([res.payload]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reports.csv');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(url);

        toast.info('Successfully Downloaded File');
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const tryCatch = async (params, data = null) => {
    // setData([]);

    try {
      const response = await dispatch(getAudits({ params: params, id: userInfo?.id }));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          orderID: dd?.id,
          price: dd?.price,
          riderName: dd?.rider,
          foodCode: dd?.food_code,
          customerName: dd?.customer,
          date: dd?.order_date,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handleTransactions = () => {
    const unfinalParams = {
      page: filter?.page,
      search: filter?.search,
      sort_by: filter?.sortBy,
      ['include[0]']: 'items',
      per_page: filter?.per_page,
      ['include[1]']: 'customer',
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      search: filter?.search,
      sort_by: filter?.sortBy,
      per_page: filter?.per_page,
      //
      ['include[0]']: 'items',
      ['include[1]']: 'customer',
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        sort_by: filter?.sortBy,
        ['include[0]']: 'items',
        per_page: filter?.per_page,
        ['include[1]']: 'customer',
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  const handleFilter = (filterBy) => {
    switch (filterBy) {
      case 'Ascending':
        setFilter((prev) => ({
          ...prev,
          sortBy: 'ASC',
        }));

        const ascendingParams = {
          sort_by: 'ASC',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
          //
          ['include[0]']: 'items',
          ['include[1]']: 'customer',
        };

        const finalAscendingParams = objectCleaner(ascendingParams);

        tryCatch(finalAscendingParams);
        break;
      case 'Descending':
        setFilter((prev) => ({
          ...prev,
          sortBy: 'DESC',
        }));

        const descendingParams = {
          sort_by: 'DESC',
          page: filter?.page,
          search: filter?.search,
          per_page: filter?.per_page,
          //
          ['include[0]']: 'items',
          ['include[1]']: 'customer',
        };

        const finalDescendingParams = objectCleaner(descendingParams);

        tryCatch(finalDescendingParams);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleTotalAmount = async () => {
      try {
        axios.get(`api/merchant-audit-total-amount/${userInfo?.id}`).then((response) => {
          setTotalAmount(response?.data?.totalamount);
        });
      } catch (error) {
        // console.log('Error', error);
      }
    };

    if (userInfo) {
      handleTotalAmount();
      handleTransactions();
    }
  }, [userInfo]);

  return (
    <PrivateLayout pageTitle='Audits'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>AUDIT</h4>
              <p>
                <span>AUDIT</span> / AUDIT LIST
              </p>
            </div>

            <div className={styles?.actions}>
              <Button disabled={exportAuditLog?.isLoading} onClick={handleExport}>
                <p>Export to Excel</p> <ExportSVG />
              </Button>

              <Dropdown>
                <Dropdown.Toggle variant='success' className={styles?.filter}>
                  {filter?.sortBy !== '' ? filter?.sortBy : 'Order By'}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {filter?.sortBy !== 'Ascending' && (
                    <Dropdown.Item onClick={() => handleFilter('Ascending')}>
                      Ascending
                    </Dropdown.Item>
                  )}

                  {filter?.sortBy !== 'Descending' && (
                    <Dropdown.Item onClick={() => handleFilter('Descending')}>
                      Descending
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                TRANSACTIONS<span>Table of Transactions</span>
              </p>

              <Table striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Food Code</th>
                    <th>Customer Name</th>
                    <th>Rider Name</th>
                    <th>Price</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((dd, idx) => (
                      <tr key={idx}>
                        <td>{dd?.orderID}</td>
                        <td>{dd?.foodCode}</td>
                        <td>{dd?.customerName}</td>
                        <td>{dd?.riderName}</td>
                        <td>{dd?.price}</td>
                        <td>{dd?.date}</td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>Total Amount</td>
                    <td colSpan={2}>Php {totalAmount}</td>
                  </tr>
                </tfoot>
              </Table>

              <TablePagination
                meta={auditMeta}
                setParams={setFilter}
                loading={auditData?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default Audit;
