/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import setAuthToken from 'services/setAuthToken';
import { selectAuth } from 'reducers/authReducer';

// Public Routes
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import PageNotFound from 'pages/404';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';

// Private Routes
import Audit from 'pages/Audit';
import Riders from 'pages/Riders';
import Promos from 'pages/Promos';
import Orders from 'pages/Orders';
// import Accounts from 'pages/Accounts';
import AddRider from 'pages/AddRider';
import Products from 'pages/Products';
import EditPromo from 'pages/EditPromo';
import Dashboard from 'pages/Dashboard';
import Merchants from 'pages/Merchants';
import ViewRider from 'pages/ViewRider';
import AddPromos from 'pages/AddPromos';
import AddProduct from 'pages/AddProduct';
import UserAccount from 'pages/UserAccount';
import AddMerchant from 'pages/AddMerchant';
import UploadMaster from 'pages/UploadMaster';
import Transactions from 'pages/Transactions';
import ViewMerchant from 'pages/ViewMerchant';
import UpdateProduct from 'pages/UpdateProduct';
import GenerateReport from 'pages/GenerateReport';
import ApproveDenyRider from 'pages/ApproveDenyRider';
import ViewPendingMerchant from 'pages/ViewPendingMerchant';
import UpdateMerchantProduct from 'pages/UpdateMerchantProduct';
import ViewTransaction from 'pages/ViewTransaction';

const SystemRoutes = () => {
  const loginStoreState = useSelector(selectAuth);
  const { token, isAuthenticated, user_type } = loginStoreState;

  setAuthToken(token);

  return isAuthenticated ? (
    // Private Routes
    <Routes>
      {user_type === 'admin' ? (
        <>
          <Route path='/' element={<Dashboard />} />
          <Route path='/promos' element={<Promos />} />
          <Route path='/riders' element={<Riders />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/riders/add' element={<AddRider />} />
          <Route path='/merchants' element={<Merchants />} />
          <Route path='/promos/:id' element={<EditPromo />} />
          <Route path='/user-account' element={<UserAccount />} />
          <Route path='/transactions' element={<Transactions />} />
          <Route path='/riders/view/:id' element={<ViewRider />} />
          <Route path='/promos/add-promo' element={<AddPromos />} />
          <Route path='/transactions/:id' element={<ViewTransaction />} />
          <Route path='/merchants/active/:id' element={<ViewMerchant />} />
          <Route path='/riders/action/:id' element={<ApproveDenyRider />} />
          <Route path='/merchants/upload-file' element={<UploadMaster />} />
          <Route path='/merchants/add-merchant' element={<AddMerchant />} />
          <Route path='/dashboard/generate-report' element={<GenerateReport />} />
          <Route path='/merchants/pending/:id' element={<ViewPendingMerchant />} />
          <Route path='/merchants/product/:id' element={<UpdateMerchantProduct />} />
          {/* <Route path='/accounts' element={<Accounts />} /> */}
        </>
      ) : (
        <>
          <Route path='/audit' element={<Audit />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/products' element={<Products />} />
          <Route path='/products/add-product' element={<AddProduct />} />
          <Route path='/products/edit-product/:id' element={<UpdateProduct />} />
          <Route path='/orders/generate-report' element={<GenerateReport />} />
        </>
      )}

      <Route path='*' element={<PageNotFound />} />
    </Routes>
  ) : (
    // Public Routes
    <Routes>
      {/* Admin Login */}
      <Route path='/admin-login' element={<Login type='admin' />} />

      {/* Merchant Login */}
      <Route path='/' element={<Login type='merchant' />} />

      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/password-reset' element={<UpdatePassword />} />
      <Route path='/account-recovery' element={<ForgotPassword />} />

      <Route path='*' element={<Login type='merchant' />} />
    </Routes>
  );
};

export default SystemRoutes;
