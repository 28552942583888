/* eslint-disable no-unused-vars */
import { BrowserRouter } from 'react-router-dom';

// Routes
import SystemRoutes from 'components/Routes';

const AppRouter = () => {

  return (
    <BrowserRouter>
      <SystemRoutes />
    </BrowserRouter>
  );
};

export default AppRouter;
