/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Container, Row, Col, Table, OverlayTrigger, Popover } from 'react-bootstrap';

// Components
import { objectCleaner } from 'utils/objectCleaner';
import PrivateLayout from 'components/GlobalUI/PrivateLayout';
import TablePagination from 'components/GlobalUI/TablePagination';
import { getPromos, getpromosState, deletePromo } from 'reducers/promosReducer';
import ConfirmAction from 'components/GlobalUI/Modal/ConfirmAction/ConfirmAction';

// Assets
import styles from './index.module.scss';
import { ReactComponent as PlusSVG } from 'assets/svg/plus.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/action.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/magnifying-glass.svg';

// Main component
const Promos = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef();

  const [showConfirm, setShowConfirm] = useState({
    type: '',
    data: null,
    show: false,
    submit: null,
  });

  const handleAddPromo = () => {
    navigate('/promos/add-promo');
  };

  const handleEdit = (dd) => {
    navigate(`/promos/${dd?.id}`);
  };

  const promoState = useSelector(getpromosState);
  const { meta, logs, deleteLogs } = promoState;

  const [tableData, setTableData] = useState([]);

  const [filter, setFilter] = useState({
    page: 1,
    search: '',
    per_page: 5,
  });

  const handlePromos = () => {
    const unfinalParams = {
      page: filter?.page,
      search: filter?.search,
      per_page: filter?.per_page,
    };

    const params = objectCleaner(unfinalParams);

    tryCatch(params);
  };

  const tryCatch = async (params) => {
    setTableData([]);

    try {
      const response = await dispatch(getPromos(params));

      const newData = response?.payload?.data?.map((dd) => {
        return {
          ...dd,
          promoName: dd?.name,
          promoCode: dd?.code,
          promoPrice: dd?.price,
          promoExpiry: dd?.end_date,
          paymentMethod: dd?.payment_method,
        };
      });

      setTableData(newData);
    } catch (error) {
      toast.error('Something went wrong.');
    }
  };

  const handlePaginationClick = ({ page }) => {
    setFilter((prev) => ({
      ...prev,
      page: page,
    }));

    const newObj = {
      page: page,
      per_page: 5,
      type: filter?.type,
      search: filter?.search,
    };

    const params = objectCleaner(newObj);

    tryCatch(params);
  };

  const handleHideConfirm = () => {
    setShowConfirm({
      type: '',
      data: null,
      show: false,
    });
  };

  const handleDeactivation = async (id) => {
    try {
      const response = await dispatch(deletePromo(id));

      if (response) {
        toast.info('Successfully deleted promo');
        handlePromos();
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({ ...prev, [name]: value }));

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setFilter((prev) => ({ ...prev, page: 1 }));

      const newObj = {
        page: 1,
        search: value,
        type: filter?.type,
        per_page: filter?.per_page,
      };

      const params = objectCleaner(newObj);

      tryCatch(params);
    }, 1000);
  };

  useEffect(() => {
    handlePromos();
  }, []);

  return (
    <PrivateLayout pageTitle='Promos'>
      <Container>
        <Row className='mb-5'>
          <Col className={styles?.header}>
            <div className={styles?.text}>
              <h4>PROMOS</h4>
              <p>
                <span>PROMOS</span> / PROMOS LIST
              </p>
            </div>

            <div className={styles?.actions}>
              <Button onClick={handleAddPromo}>
                <p>Add New Promo</p> <PlusSVG />
              </Button>

              <div className={styles?.search}>
                <Form.Control
                  name='search'
                  placeholder='Search'
                  value={filter?.search}
                  onChange={handleSearch}
                />
                <EyeSVG />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className='p-0'>
            <div className={styles?.table}>
              <p className={styles?.tableHeader}>
                PROMOS LIST<span>List of Promo Offers</span>
              </p>

              <Table responsive striped bordered hover className={styles?.table}>
                <thead>
                  <tr>
                    <th>Promo Name</th>
                    <th>Promo Code</th>
                    <th>Promo Price</th>
                    <th>Promo Expiry</th>
                    <th>Payment Method</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((dd) => (
                      <tr key={dd?.id}>
                        <td>{dd?.promoName}</td>
                        <td>{dd?.promoCode}</td>
                        <td>{dd?.promoPrice}</td>
                        <td>{dd?.promoExpiry}</td>
                        <td>{dd?.paymentMethod}</td>
                        <td>
                          <OverlayTrigger
                            trigger='click'
                            placement='left'
                            rootClose
                            overlay={
                              <Popover id='popover-table-action'>
                                <Popover.Body className='d-flex justify-content-between p-0'>
                                  <div className='button-actions'>
                                    <Button onClick={() => handleEdit(dd)} className='edit'>
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        setShowConfirm({
                                          data: dd?.id,
                                          show: true,
                                          submit: handleDeactivation,
                                          type: 'deletion of selected promo?',
                                        })
                                      }
                                      className='delete'
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Button
                              variant='secondary'
                              className='popoverButton bg-transparent p-0 border-0'
                            >
                              <ActionSVG className={styles?.action} />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  <tr></tr>
                </tbody>
              </Table>

              <TablePagination
                meta={meta}
                setParams={setFilter}
                loading={logs?.isLoading}
                handlePaginationClick={handlePaginationClick}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <ConfirmAction
        data={showConfirm?.data}
        show={showConfirm?.show}
        type={showConfirm?.type}
        onHide={handleHideConfirm}
        handleConfirm={showConfirm?.submit}
      />
    </PrivateLayout>
  );
};

export default Promos;
